import axios from 'axios';
import * as ApiEndPoints from './ApiEndPoints';

const scorecardInstance = axios.create({
    baseURL: ApiEndPoints.API_SCORECARD
});

scorecardInstance.defaults.headers["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept";
scorecardInstance.defaults.headers['Access-Control-Allow-Methods'] = "GET, POST, PATCH, PUT, DELETE, OPTIONS'";

scorecardInstance.defaults.headers.post['Content-Type'] = 'application/json';
scorecardInstance.defaults.timeout = 30000;

scorecardInstance.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(error);
});
 
export default scorecardInstance;
