import {SUBMIT_FEEDBACK_FORM, SUBMIT_FEEDBACK_FORM_SUCCESS}from "./FeedBackConstant"

export const feedBackSubmitAction = (feedback="", deviceType="DESKTOP", userId="") => ({
    type: SUBMIT_FEEDBACK_FORM,
    feedback,
    deviceType,
    userId
    
});

export const feedBackSubmitSuccess = (response) => ({
    type: SUBMIT_FEEDBACK_FORM_SUCCESS,
    data: response
});