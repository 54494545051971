import  {PERFORM_LOGIN, REMOVED_TOKENS_SUCCESS, AUTH_SUCCESS, LOGOUT_LOADER, INITIAL_DATA_DOWNLOAD_SUCCESS } from './AuthenticationConstants';
import {trimUserId} from './AuthenticationHandler';

const initialState={
    accessToken : undefined,
    idToken: undefined,
    validUntil: undefined,
    firstName: "",
    lastName:"",
    userId:"",
    role:"",
    decodedToken:{},
    isAdmin: false,
    logoutLoader: false,
    registerData: {}
};

export const AuthenticationReducer =(state=initialState, action) =>{
    switch(action.type) {
        case PERFORM_LOGIN: {
            return {
                ...state,
                accessToken: action.payload
            }
        }
       
        case AUTH_SUCCESS: {
            window.dynatraceUserId = action.payload.SM_USER;
            return (action.payload.SM_USER && action.payload.SM_USER.substr(0,2) === '00' ?
                {
                    ...state,
                    accessToken : action.payload.access_token,
                    idToken: action.payload.access_token,
                    validUntil: action.payload.exp,
                    firstName: action.payload.SM_USER_FIRSTNAME,
                    lastName: action.payload.SM_USER_LASTNAME,
                    userId: trimUserId(action.payload.SM_USER),
                    role: action.payload.SM_ROLE,
                    decodedToken: { 
                        ...action.payload, 
                        SM_USER: trimUserId(action.payload.SM_USER),  
                    },
                    isAdmin: action.payload.SM_ROLE === "Rovr_RT_Adm"  || action.payload.SM_ROLE === "Rovr_Admin" || action.payload.SM_ROLE === "Rovr_Super_Admin",
                    logoutLoader: false
                } 
                :
                {
                    ...state,
                    accessToken : action.payload.access_token,
                    idToken: action.payload.access_token,
                    validUntil: action.payload.exp,
                    firstName: action.payload.SM_USER_FIRSTNAME,
                    lastName: action.payload.SM_USER_LASTNAME,
                    userId: action.payload.SM_USER,
                    role: action.payload.SM_ROLE,
                    decodedToken: {
                        ...action.payload,
                        amrcompany: action.payload.amrcompany === "" ? "AA" : action.payload.amrcompany
                    },
                    isAdmin: action.payload.SM_ROLE === "Rovr_RT_Adm" || action.payload.SM_ROLE === "Rovr_Admin" || action.payload.SM_ROLE === "Rovr_Super_Admin",
                    logoutLoader: false
                }
            )

        }
        case LOGOUT_LOADER: {
            return {
                ...state,
                logoutLoader: true
            }
        }
        case REMOVED_TOKENS_SUCCESS : {
                return initialState;
        }
        case INITIAL_DATA_DOWNLOAD_SUCCESS: {
            return {
                ...state,
                registerData: action.payload,
            }
        }
        default: {
            return state;
        }
    } 

}

export default AuthenticationReducer;