import React, { useState } from 'react';
import { Button, Modal, ModalTitle } from 'react-bootstrap';
import resources from '../../../utils/resources/resources.json';
import {Steps} from './Steps';
import {TourSteps} from './tourSteps';
import {learnRovrData} from './learnRovrData';
import { useHistory } from "react-router-dom";

const LearnRovrModal = (props) => {

    const history = useHistory();
    const [currentItem, setCurrentItem] = useState(props.show)
   
    const handleClose = () => {
        props.setShowLearnRovr(null)
        history.push('/')
    }

    const nextItem = () =>{
        let current = currentItem;
        let next = current + 1;
        if (learnRovrData[currentItem].title == 'Color Code') {
            if (!props.code113Enabled) {
                    next = next + 1;
            }
        } 
        setCurrentItem(next%learnRovrData.length);
    }

    return (
        <>
            <Modal dialogClassName="fullscreenmodal"
                show={currentItem != null ? true: false}

                backdrop="static"
                keyboard={false}
                id="about"
            >
                <Modal.Header>

                    <Modal.Title className="aboutHead">{learnRovrData[currentItem].title}
                    {currentItem !== 4 ? learnRovrData[currentItem].img : ''}</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                   
                    <ModalTitle className="titleBody stepBody">
                        {learnRovrData[currentItem].text}
                        </ModalTitle>
                   
                </Modal.Body>
                <div className='stepTourButtons'>
                    <Button className="mr-2 buttonSearch submitColor buttonTour"
                    onClick={handleClose}>
                        Exit
                    </Button>
                    <Button className="mr-2 buttonSearch resetColor buttonTour"
                    onClick={nextItem}>
                        Next
                    </Button>
                </div>
            </Modal>
        </>
    )
}

export default LearnRovrModal;