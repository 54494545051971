import { SHOW_ERROR_MESSAGE, REMOVE_ERROR_MESSAGE, SHOW_LOADING_INDICATOR, REMOVE_LOADING_INDICATOR } from './ErrorConstant'

export const showErrorAction = (errorResponse) => {
    return {
        type: SHOW_ERROR_MESSAGE,
        error: { errorCode: errorResponse.errorCode, errorMessage: errorResponse.errorMessage }
    }
};


export const removeErrorAction = () => ({
    type: REMOVE_ERROR_MESSAGE,
    error: { errorCode: '', errorMessage: '' }
});

export const showLoaderAction = () => ({
    type: SHOW_LOADING_INDICATOR
});

export const removeLoaderAction = () => ({
    type: REMOVE_LOADING_INDICATOR
});