import React, {useState, Fragment, useContext, useRef, useEffect} from 'react';
import { Button, Card, Accordion, AccordionContext } from "react-bootstrap";
import { useAccordionToggle } from 'react-bootstrap';
import LavatoryIcon from "../../../assets/images/Web_Lavatory_Icon.svg";
import StatusInactiveIcon from "../../../assets/images/Web_Lavatory_Inactive_Icon.svg";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import DetailsComponent from "../../../modules/details/DetailsComponent";
import ArrivalFlightIcon from "../../../assets/images/Web_FlightArrival_Icon.svg";
import DropdownIcon from "../../../assets/images/Web_Dropdown_Icon.svg";
import DropdownExpandIcon from "../../../assets/images/Web_Dropdown_Expand_Icon.svg";
import { TourSteps } from './tourSteps'
import UnknowledgeIcon from "../../../assets/images/Web_Unknowledge_Icon.svg";
import timer1 from '../../../assets/images/Web_Timer01_Icon.svg';
import timer2 from '../../../assets/images/Web_Timer02_Icon.svg';
import timer3 from '../../../assets/images/Web_Timer03_Icon.svg';
import timer4 from '../../../assets/images/Web_Timer04_Icon.svg';
import timer5 from '../../../assets/images/Web_Timer05_Icon.svg';
import CancelIcon from "../../../assets/images/Web_Cancel_Icon.svg";
import CompleteIcon from "../../../assets/images/Web_Complete_Icon.svg";
import { SquenceAnimator }  from 'react-sequence-animator';


const DetailToggleButton = ({ eventKey, handleChange, isOpen,currentTourStep, nextTourStep}) => {
    const currentEventKey = useContext(AccordionContext);
   
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => {
        if (currentTourStep == 6 || currentTourStep == 7) {
            nextTourStep()
            if(isOpen == 'open') {
            handleChange(null)
            } else {
                handleChange('open')
            }
        } else {
            return
        }
      }
    );
    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <button
        type="button"
        className="TaskToggleButton expandTour"
        onClick={decoratedOnClick}
      >
        <img
          src={isCurrentEventKey ? DropdownExpandIcon : DropdownIcon}
          alt=""
        />
      </button>
    );
  };

const TourModeTask = (props) => {
    const {
        currentTourStep,
        handActionButton,
        isOpen,
        setIsOpen,
        nextTourStep,
        previousTourStep,
    } = props
    const date = new Date()
    const [showTimer, toggleTimer] = useState(false);
    const timerSequence = useRef();

    const taskState = {
        'CONFIRMED' : {background : '#ff9800', icon:  StatusInactiveIcon},
        'STARTED' : {background :'#14e715', icon: LavatoryIcon},
    }

    const stopTimer = () => {
        previousTourStep()
        timerSequence.current.reset();
        toggleTimer(!showTimer);
    }


    const sequenceCompleted = () =>{
        nextTourStep()
        toggleTimer(!showTimer);
    }

    const getBlur = () => {
        if (TourSteps[currentTourStep].state == 'ASSIGNED' || currentTourStep == 10 || currentTourStep == 11 ) {
            return 'blur(3px)'
        }
        return ''
    }

    return (
        <div >
            <div className="container d-flex justify-content-between" style={{alignSelf: "flex-start", paddingLeft:0,
                paddingRight: 0}}>
                <h6 className='titleTaskTour'>Task Information - 1 Tasks</h6>
            </div>
        <div className='accordion' style={{border: '1px solid #c7c7c7'}}>
            <thead className="TaskHeader tourHeader">
                <tr>
                    <th > Task</th>
                    <th > Arrival/Departure</th>
                    <th > Location</th>
                    <th > Aircraft</th>
                    <th > Time</th>
                    <th colSpan="2" >Status</th>
                </tr>
            </thead>
            <tr key={1} style= {TourSteps[currentTourStep].state == 'ASSIGNED'? 
            {background: 'rgb(0 0 0 / 46%)'} : {background: ''}}>
                <td style={{filter:  getBlur(), display:'flex', padding: '1px', paddingBottom: '0px',alignItems:'stretch', textAlign:'center'}}>
                    {currentTourStep < 10 ?
                    <div style={taskState[TourSteps[currentTourStep].state] ? {backgroundColor: taskState[TourSteps[currentTourStep].state].background, color: taskState[TourSteps[currentTourStep].state].background } : null}  className='taskItemStrip'>
                    {' _'}</div>
                    :null}
                    <div style={{alignSelf: 'center' ,paddingLeft: '2%', width: '100%', margin: '10px'}}>
                    Tour Mode          
                    </div>
                </td>
                <td className='tdTour' style={{filter:  getBlur() ,fontSize: '16px', color:'#36495A', verticalAlign: 'middle', paddingLeft: '40px', paddingRight: '40px'}}>
                    <div className="flight-info">
                        <figure>
                            <img src={ArrivalFlightIcon} alt="flight type icon" />
                            <div className="flight-city-diversion" style={{marginRight:'2px'}}>
                            </div> 
                            <div className="flight-city-diversion">
                                A00
                            </div>                                  
                            <div className="flight-intl-flag">INTL</div>
                        </figure>
                        <aside >
                            <p>AA0000</p>
                            <p>IN {date.getHours()}:{date.getMinutes()} - {date.getHours()}:{date.getMinutes()}</p>
                        </aside>
                    </div>
                </td>
                <td className='tdTour' style={{ filter:  getBlur() ,alignSelf: 'center' ,paddingLeft: '2%', paddingLeft: '40px', paddingRight: '40px', textAlign:'center'}}>
                    A00
                </td>
                <td className='tdTour' style={{filter:  getBlur() ,paddingLeft: '40px', paddingRight: '40px'}}>
                    738K/3FD
                </td>
                <td className='tdTour' style={{paddingLeft: '40px', paddingRight: '40px'}}>
                {TourSteps[currentTourStep].state == 'ASSIGNED'? 
                    <div style=
                        {{position: 'absolute',
                            zIndex: 5,
                            display: 'flex',
                            alignItems: 'start-flex'
                            }}
                            onClick={nextTourStep}>
                        <p style={{fontWeight: '700',
                        fontSize: 'Larger',color: 'white'}}>Task Added</p>
                        <img src={UnknowledgeIcon} style={{marginLeft: '20px', position: 'relative', bottom: '30px'}}/>
                        <Player src={require('../../../assets/animations/tapHand.json')}
                                background="transparent"  
                                speed="1"
                                style={{ marginLeft: 5, marginTop: 5, width: 80, height: 80, position: 'relative', left: '-58px'}}
                                autoplay loop
                            /> 
                    </div> : null
                }
                <p style={{filter: getBlur()}}>

                {date.getHours()}:{date.getMinutes()}
                </p>
                </td>
                {currentTourStep < 10 ?
                (<>
                <td style={{filter:  getBlur() ,paddingTop: '20px',verticalAlign: 'middle', width: '7%'}}>
                    <button className="lavatorButton"  onClick={handActionButton.includes(currentTourStep) ? nextTourStep : null} style={{margin:'10px',verticalAlign: 'middle',}} id="actionButton">
                        {handActionButton.includes(currentTourStep) && TourSteps[currentTourStep].state !== 'ASSIGNED'  ? 
                        <Player src={require('../../../assets/animations/tapHand.json')}
                            background="transparent"  
                            speed="1"
                            style={{ marginLeft: 5, marginTop: 5, width: 80, height: 80, position: 'absolute',}}
                            autoplay loop
                        /> : null }
                        {TourSteps[currentTourStep].state !== 'ASSIGNED'?
                        <img src={taskState[TourSteps[currentTourStep].state] ? taskState[TourSteps[currentTourStep].state].icon : null} alt="LavatoryIconConfirmed"  />
                        : null }
                    </button>
                </td>
                <td style=
                {{filter: getBlur()}} colSpan="6" className='toggleButtonTour'>
                    <Accordion>
                        <DetailToggleButton
                        eventKey={'open'}
                        handleChange={(state)=>setIsOpen(state)}
                        isOpen={isOpen}
                        nextTourStep={()=>nextTourStep()}
                        currentTourStep={currentTourStep}
                        />
                    </Accordion>
                    {!handActionButton.includes(currentTourStep) && !TourSteps[currentTourStep].modal && TourSteps[currentTourStep].state !== 'ASSIGNED' ?
                        <Player src={require('../../../assets/animations/tapHand.json')}
                            background="transparent"  
                            speed="1"
                            style={{marginLeft: 5, marginTop: 5, width: 80, height: 80, position: 'absolute',
                                top: '385px'
                            }}
                            autoplay loop 
                        />
                    : null }  
                </td>
                </> )
                :
                currentTourStep == 10  || currentTourStep == 11?
                <>
                        <div className="completeButtonCont" style={{zIndex: 5, marginTop: '32px'}}>
                        <Button className="completeBtn" id= "actionButton" onClick={previousTourStep}>
                            <img src={CancelIcon} alt="Status active icon"  />
                          </Button>
                          <Button className="completeBtn" id={"actionButton" } onClick={nextTourStep}>
                            <img src={CompleteIcon} alt="Status active icon" />
                          </Button>
                        </div>
                        </>
                    :
                    currentTourStep == 13 ?
                        <div onClick={() => stopTimer()}>
                          <SquenceAnimator duration={5000} loop={false} onSequenceEnd={sequenceCompleted} ref={timerSequence}>
                            <img src={timer5} alt="timer5"/>
                            <img src={timer4} alt="timer4"/>
                            <img src={timer3} alt="timer3"/>
                            <img src={timer2} alt="timer2"/>
                            <img src={timer1} alt="timer1"/>
                          </SquenceAnimator>
                        </div>  
                : 
                <>
                </> }
            </tr>
            <Accordion.Collapse
                eventKey={isOpen}
                >
                <Card.Body>
                    <Fragment>
                        <DetailsComponent
                        action={null}
                        commentsList={''}
                        decodedToken={{}}
                        empName={'Acknowledged'}
                        showNameFlag={false}
                        taskRoles={{}}
                        csCrewList={[{
                            firstName:'Crew',
                            lastName:'Crew',
                            station:'DFW',
                            userId:'h',
                            ackRequired:false,
                            startTime:`${date.getHours()}:${date.getMinutes()}`,
                            endTime:`${date.getHours()}:${date.getMinutes()+60}`,
                            taskStatus: 'CONFIRMED',
                            tlinkRoute: false,
                            taskName:'D1',
                        }]}
                        fsCrewList={[
                            {firstName:'Crew',
                            lastName:'Crew',
                            station:'DFW',
                            userId:'h',
                            ackRequired:false,
                            startTime:`${date.getHours()}:${date.getMinutes()}`,
                            endTime:`${date.getHours()}:${date.getMinutes()+60}`,
                            taskStatus: 'STARTED',
                            tlinkRoute:false,
                            taskName:'D1',
                            }]}
                        fetchDetailsParams={{}}
                        selectedTaskBar={{}}
                        callForDetailsAction={{}}
                        setRolesCount={0}
                        crewChief={false}
                        loginAsData={false}
                        callForAllUserDetails={()=>null}
                        shiftInfoList={{}}
                        ccRoleEnabled={false}
                        allowedTaskRoles={[]}
                        />
                    </Fragment>
                </Card.Body>    
            </Accordion.Collapse>
        </div>
        </div>
    )
}

export default TourModeTask;