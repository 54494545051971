import { combineReducers } from 'redux';
import taskAndShiftReducer from '../../modules/task/taskAndShiftReducer';
import detailsReducer from '../../modules/details/detailsReducer';
import searchReducer from '../../modules/search/searchReducer';
import AuthenticationReducer from '../../modules/authentication/AuthenticationReducer';
import notificationReducer from '../../modules/notification/NotificationReducer';
import faqsReducer from '../../modules/faqs/FaqsReducer';
import feedBackReducer from '../../modules/feed-back/FeedBackReducer';
import loginReducer from '../../modules/login/LoginReducer';
import errorReducer from '../../modules/error/ErrorReducer';
import code113Reducer from '../../common/components/code113Modal/code113Reducer';
import scorecardReducer from '../../modules/scorecard/scorecardReducer';
import shiftReducer from '../../modules/shift/shiftReducer';
import taskHistoryReducer from '../../modules/taskHistoryModule/taskHistoryReducer';

const rootReducer = combineReducers({
    taskAndShiftReducer, 
    detailsReducer, 
    searchReducer, 
    AuthenticationReducer, 
    notificationReducer, 
    faqsReducer, 
    feedBackReducer, 
    loginReducer, 
    errorReducer, 
    code113Reducer,
    scorecardReducer,
    shiftReducer,
    taskHistoryReducer
});

export default rootReducer;