import {
  createStore, applyMiddleware, compose,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './Reducer/reducer';
import {persistStore } from 'redux-persist';
import {watchGetTip, watchTourMode, watchCode113, watchTaskandShift, watchCrewDetails, watchSearch, watchAuthentication, watchNotification, watchFaqs, watchFeedback, watchLogin, watchSearchDefault, watchSearchShifts, watchSetPopupFsCrew, watchTaskHistory } from './Sagas/rootSaga';

const sagaMiddleware = createSagaMiddleware();
// const persistConfig = {
//   key:'root', storage
// }
// const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = process.env.NODE_ENV === 'production' ? compose : (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose);
// eslint-disable-next-line import/no-anonymous-default-export
const configureStore = () => {
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
  sagaMiddleware.run(watchTaskandShift);
  sagaMiddleware.run(watchCrewDetails);
  sagaMiddleware.run(watchSearch);
  sagaMiddleware.run(watchAuthentication);
  sagaMiddleware.run(watchLogin);
  sagaMiddleware.run(watchNotification);
  sagaMiddleware.run(watchFaqs);
  sagaMiddleware.run(watchFeedback);
  sagaMiddleware.run(watchCode113);
  sagaMiddleware.run(watchGetTip);
  sagaMiddleware.run(watchSearchDefault);
  sagaMiddleware.run(watchSearchShifts);
  sagaMiddleware.run(watchSetPopupFsCrew);
  sagaMiddleware.run(watchTaskHistory);
  sagaMiddleware.run(watchTourMode);

  let persistor = persistStore(store)
  return {store, persistor};

}
export default configureStore;
