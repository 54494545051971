import {GET_STATION_LIST_SUCCESS,
    GET_STATION_GATES_SUCCESS,
    GET_WORK_AREA_SUCCESS,
    GET_SEARCH_RESULT_SUCCESS,
    SHOW_LOADER,
    SET_STATION_SEARCH,
    GET_WORK_AREA_ERROR,
    GET_SEARCH_RESULT_ERROR,
    GET_STATION_GATES_ERROR,
    GET_STATION_LIST_ERROR,
    
    PUT_SEARCH_DEFAULT,
    PUT_SEARCH_DEFAULT_SUCCESS,
    PUT_SEARCH_DEFAULT_ERROR,
    GET_SEARCH_DEFAULT,
    GET_SEARCH_DEFAULT_SUCCESS,
    GET_SEARCH_DEFAULT_ERROR,

		DELETE_SEARCH_DEFAULT,
		DELETE_SEARCH_DEFAULT_SUCCESS,

		CHANGE_DEFAULT_COMPLETED_FALSE,
		CHANGE_DEFAULT_COMPLETED_TRUE,
    CLEAR_SEARCH_RESULTS
    } from './searchConstant';

const initialState = {
    stationList: [],
    stationGates: [],
    workArea: [],
    searchResult: [],
    showLoader: false,
		showClearLoader: false,
    selectedStation: '',
		putDefaultCompleted: false,
		firstSearchCompleted: false,
    searchDefault: {
			xhrRequest: 0,
			department: "",
			stationCode: "",
			searchCriteria: "",
			searchValues: [],
      searchSubValues: [],
      searchSubValues2: [],
			searchValue: ""
		}
}

function searchReducer(state = initialState, action) {
	
    switch(action.type) {
        case GET_STATION_LIST_SUCCESS: {
            return {
                ...state,
                stationList: action.response
            }
        }
        case GET_STATION_GATES_SUCCESS: {
            return {
                ...state,
                stationGates: action.response
            }
        }
        case GET_WORK_AREA_SUCCESS: {
            return {
                ...state,
                workArea: action.response
            }
        }
        case GET_SEARCH_RESULT_SUCCESS: {
            return {
                ...state,
                searchResult: action.response,
                showLoader: false,
								firstSearchCompleted: true,
            }
        }
        case SHOW_LOADER: {
            const loaderResponseFlag = typeof action.response == "boolean" ? action.response : true
            return {
                ...state,
                showLoader: loaderResponseFlag
            }
        }
        case SET_STATION_SEARCH: {
            return {
                ...state,
                selectedStation: action.station
            }
        }
        case GET_SEARCH_RESULT_ERROR: {
            return {
              ...state,
              apiError: action.payload,
            }
          }
          case GET_STATION_GATES_ERROR: {
            return {
              ...state,
              apiError: action.payload
            }
          }
          case GET_STATION_LIST_ERROR: {
            return {
              ...state,
              apiError: action.payload
            }
          }
          case GET_WORK_AREA_ERROR: {
            return {
              ...state,
              apiError: action.payload
            }
          }

           case GET_SEARCH_DEFAULT: {
            return {
              ...state,
							showClearLoader: true,
          	}
          }

          case GET_SEARCH_DEFAULT_SUCCESS: {
						let resp = action.response;

						resp['xhrRequest'] = 1;
            resp['department'] =  resp.department == null ? "" : resp.department
            resp['stationCode'] =  resp.stationCode == null ? "" : resp.stationCode
            resp['searchCriteria'] =  resp.searchCriteria == null ? "" : resp.searchCriteria
            resp['searchValues'] = resp.searchValues == null ? [] : resp.searchValues
            resp['searchSubValues'] = resp.searchSubValues == null ? [] : resp.searchSubValues
            resp['searchSubValues2'] = resp.searchSubValues2 == null ? [] : resp.searchSubValues2
            resp['searchValue'] = resp.searchValue == null ? "" : resp.searchValue

            return {
              ...state,
              searchDefault: resp,
              showClearLoader: false
            }
          } 

          case GET_SEARCH_DEFAULT_ERROR: {
            return {
              ...state,
              showClearLoader: false
            }
          }

          case PUT_SEARCH_DEFAULT: {
            return {
              ...state,
              showLoader: true,
							putDefaultCompleted: false
            }
          }

          case PUT_SEARCH_DEFAULT_SUCCESS: {
						return {
							...state,
							showLoader: false,
							searchDefault: action.response,
							putDefaultCompleted: true
						}
          }

          case PUT_SEARCH_DEFAULT_ERROR: {
            return {
              ...state,
              showLoader: false,
							putDefaultCompleted: false
            }
          }

					case DELETE_SEARCH_DEFAULT : {
						return {
							...state,
							showClearLoader: true
						}
					}

					case DELETE_SEARCH_DEFAULT_SUCCESS : {
            let initialSearchDefault = JSON.parse(JSON.stringify(initialState.searchDefault));
            initialSearchDefault['xhrRequest'] = 1;
						return {
							...state,
							showClearLoader: false,
							searchDefault: initialSearchDefault
						}
					}

					case CHANGE_DEFAULT_COMPLETED_FALSE : {
						return {
							...state,
							putDefaultCompleted: false
						}
					}

					case CHANGE_DEFAULT_COMPLETED_TRUE : {
						return {
							...state,
							putDefaultCompleted: true
						}
					}

          case CLEAR_SEARCH_RESULTS : {
            return {
              ...state,
              searchResult: []
            }
          }

        default: {
            return state;
        }
    } 
}

export default searchReducer;