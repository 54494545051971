import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import ErrorCloseBtnIcon from "../../assets/images/Error_Close_btn.svg";
import  warningIcon from "../../assets/images/warningIcon.png";
import  tickIcon from "../../assets/images/tick2.png";
import './ErrorComponent.scss';

function useOutsideAlerter(ref, showErrorMessage, closeError) {
  useEffect(() => {
      function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target) && showErrorMessage) {
            closeError();
          }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
          document.removeEventListener("mousedown", handleClickOutside);
      };
  }, [ref, showErrorMessage, closeError]);
}

const ErrorComponent = ({showError, closeError, apiError, errorCode}) => {
    const { showErrorMessage } = useSelector( ({ errorReducer : {showErrorMessage} }) => ({ showErrorMessage }));
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, showErrorMessage, closeError);
    let errorColorCode = 'defaultError';
    if(errorCode === 1100) {
      errorColorCode = 'Error1100';
    } else if (errorCode === 1020) {
      errorColorCode = 'Error1020';
    } else if(errorCode === 1) {
      errorColorCode = 'Error1';
    } else if(errorCode === 1013) {
      errorColorCode = 'Error1013';
    } else if(errorCode === 1022 || errorCode === 1021) {
      errorColorCode = 'Error1021';
    } else if(errorCode === 1500) {
      errorColorCode = 'Error1500';
    } else if(errorCode === 1501) {
      errorColorCode = 'Error1500';
    } else if (errorCode === 'cancelled') {
      errorColorCode = 'ErrorCancelled'
    } else if(errorCode === 200) {
      errorColorCode = 'Success200';
    } else {
      errorColorCode = 'defaultError';
    }


    return(
      showError ?
      (
        <div className={`error-container ${errorColorCode}`} ref={wrapperRef}>
        <div className="row">
          <div className="col-sm-2 col-lg-1 d-flex justify-content-center">
            {errorCode === 200 ? (
              <span>
                {/* <img src={tickIcon} className="img-responsive" alt="tickIcon" width="70px" height="70px" /> */}
              </span>
            ) : (
              <img src={warningIcon} className="img-responsive" alt="warningIcon" width="70px" height="70px" />
            )}
          </div>
          <div className="col-sm-10 col-lg-11">
            <button onClick={closeError} type="button">
            { errorCode === 'cancelled' ? 'X' : <img src={ErrorCloseBtnIcon} alt="ErrorCloseBtnIcon"/> }
            </button>
            {
              errorCode === 200 ? (
                <h4>Success</h4>
              ) : (
                <h4>Error</h4>
              )
            }
            <p>{apiError}</p>
          </div>
        </div>
        </div>
      ): null
    );
}

export default ErrorComponent;