import React, { Fragment, useState, useEffect } from "react";
import CrewComponent from "../crew/CrewComponent";
import CommentComponent from "../comment/CommentComponent";
import "./DetailsComponent.scss";
import SetRoles2 from "./setRoles2";

const DetailsComponent = (props) => {
  const [modalShowFlag, setModalShowFlag] = useState(false);
  const [isRolesButtonShowable, setIsRolesButonShowable] = useState(false);
  const showHideModal = (val) => {
    if (isRolesButtonShowable == false) {
      alert("No crew members available to assign them roles.");
      return;
    } else {
      setModalShowFlag(
        val != null && typeof val == "boolean" ? val : !modalShowFlag
      );
    }
  };

  useEffect(() => {
    if (props.fsCrewList != null) {
      let flag = true;
      let len = props.fsCrewList.length;
      for (let i = 0; i < len; i++) {
        if (props.fsCrewList[i].ackRequired == false) {
          flag = false;
          break;
        }
      }
      if (flag) {
        setIsRolesButonShowable(false); 
      } else {
        setIsRolesButonShowable(true);
      }
    }
  }, [props.fsCrewList]);

  return (
    <Fragment>
      <div className="details">
        <div className="header1 mb-3">Task Details</div>
        <div className="header2">Crew</div>
        {props.selectedTaskBar != null &&
          props.selectedTaskBar.value == "home" &&
          !props.loginAsData.userId &&
          props.crewChief == true &&
          isRolesButtonShowable == true && 
          props.ccRoleEnabled == true && (
            <button
              onClick={showHideModal}
              style={{
                marginLeft: "22px",
                marginBottom: "6px",
              }}
              className="rolesBtn rolesBtnSubmit btn btn-primary"
            >
              Roles
            </button>
          )}

        {modalShowFlag && (
          <SetRoles2
            modalShowFlag={modalShowFlag}
            showHideModal={showHideModal}
            fsCrewList={
              props.fsCrewList != null && props.fsCrewList.length > 0
                ? props.fsCrewList.filter((el) => el.taskRoleEligible == true)
                : []
            }
            superprops={props}
            action={props.action}
            taskRoles={props.taskRoles}
            fetchDetailsParams={props.fetchDetailsParams}
            callForDetailsAction={props.callForDetailsAction}
            setRolesCount={props.setRolesCount}
            callForAllUserDetails={props.callForAllUserDetails}
            allowedTaskRoles={props.allowedTaskRoles}
          />
        )}

        <div className="row mx-0">
          <div className="col-sm-6">
            <CrewComponent
              crewList={props.csCrewList}
              crewInfo={"care"}
              empName={props.empName}
              showNameFlag={props.showNameFlag}
              isCrewChief={props.crewChief}
              role={props.decodedToken.SM_ROLE}
              shiftInfoList={null}
            />
          </div>
          <div className="col-sm-6">
            <CrewComponent
              crewList={props.fsCrewList}
              crewInfo={"operations"}
              empName={props.empName}
              showNameFlag={props.showNameFlag}
              isCrewChief={props.crewChief}
              role={props.decodedToken.SM_ROLE}
              shiftInfoList={props.shiftInfoList}
            />
          </div>
        </div>
        <div className="row mx-0">
          <div className="col-sm-12 ">
            <hr />
          </div>
        </div>
        <div className="row mb-2 mx-0">
          <div className="col-sm-12">
            <CommentComponent commentsList={props.commentsList} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DetailsComponent;
