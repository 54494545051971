import { 
    TASK_AND_SHIFT_DATA_ACTION,
    TASK_AND_SHIFT_DATA_SUCCESS,
    SET_COMMENT_INDICATOR,
    TASK_ADDED_ACTION,
    TASK_DELETED_ACTION,
    TASK_ADDED_SUCCESS,
    TASK_DELETED_SUCCESS,
    GSRT_UPDATE_ACK,
    GSRT_UPDATE_START,
    GSRT_UPDATE_COMPLETE,
    LAV_FORCE_START_ACTION,
    LAV_FORCE_START_SUCCESS,
    LAV_FORCE_START_ERROR,
    LAV_FORCE_START_ERROR_REMOVE,
    COMPLETE_TASK_ACTION,
    COMPLETE_TASK_SUCCESS,
    COMPLETE_TASK_SUCCESS2,
    PERFORM_LOGOUT,
    FETCH_TASK_TIMELINE_ACTION,
    FETCH_TASK_TIMELINE_SUCCESS,
    FETCH_TASK_TIMELINE_ERROR,
    LOGIN_AUTH,
    TASK_AND_SHIFT_DATA_ERROR,
    POST_TASK_ACT_DATA_ERROR,
    COMPLETE_TASK_DATA_ERROR,
    DELETE_TASK_ACK_DATA_ERROR,
    BROADCAST_ACK,
    BROADCAST_ACK_SUCCESS,
    OCCURENCE_ACK_SUCCESS,
    CLEAR_BC_AND_TASK,
    ADD_SPINNER,
    REMOVE_SPINNER,
    EMPTY_TASK_AND_SHIFT_DATA,
    CHANGE_TASK_BAR_TO_HOME,
    CHANGE_TASK_BAR_TO_PROFILE,
    ADHOC_TASK_CREATE,
    ADHOC_TASK_CREATE_SUCCESS,
    ADHOC_TASK_CREATE_ERROR,
    OPEN_TOUR_ON_LOGIN,
    CLOSE_TOUR_POPUP,
    TOUR_MODE_COMPLETED
} from './taskAndShiftConstant'

import * as erroractions from "../error/ErrorAction";

export const fetchTaskandShiftDataAction = (userId = "", stationCode = "", phoneNumber = "D_264881", companyCode = "") => ({
    type: TASK_AND_SHIFT_DATA_ACTION,
    userId,
    stationCode,
    phoneNumber,
    companyCode
});

export const fetchTaskandShiftDataError = () => ({
    type: TASK_AND_SHIFT_DATA_ERROR
});

export const fetchTaskandShiftDataSuccess = (responseData) => (
    {
    type: TASK_AND_SHIFT_DATA_SUCCESS,
    payload: responseData
});

export const setCommentIndicator = (data) => ({
    type: SET_COMMENT_INDICATOR,
    payload: {taskRID: data.taskRID , status: data.status}
})

export const postTaskAckDataAction = (postData) => ({
    type: TASK_ADDED_ACTION,
    userId: postData.userId,
    phoneNumber: postData.phoneNumber,
    stationCode: postData.stationCode,
    companyCode: postData.companyCode,
    taskAckList: postData.taskAckList,
    alertAckList: postData.alertAckList
});
export const deleteTaskAckDataAction = (postData) => ({
    type: TASK_DELETED_ACTION,
    userId: postData.userId,
    phoneNumber: postData.phoneNumber,
    stationCode: postData.stationCode,
    companyCode: postData.companyCode,
    taskAckList: postData.taskAckList,
    alertAckList: postData.alertAckList
});

export const postTaskAckDataSucccess = (responseData, taskRID, taskInstanceId) => ({
    type: TASK_ADDED_SUCCESS,
    payload: responseData,
    taskRID,
    taskInstanceId
});

export const deleteTaskAckDataSucccess = (responseData, taskRID, taskInstanceId) => ({
    type: TASK_DELETED_SUCCESS,
    payload: responseData,
    taskRID,
    taskInstanceId
    
});

export const forceStartLavAction = (postData) => ({
    type:LAV_FORCE_START_ACTION,
    userId: postData.userId,
    stationCode: postData.stationCode,
    taskInstanceId: postData.taskInstanceId
});

export const gsrtUpdatedStatusComplete = (data) => ({
    type: GSRT_UPDATE_COMPLETE,
    state: data.state,
    taskId: data.taskId
})

export const gsrtUpdatedStatusStart = (data) => ({
    type: GSRT_UPDATE_START,
    state: data.state,
    taskId: data.taskId
})

export const gsrtUpdatedStatusAck = (data) => ({
    type: GSRT_UPDATE_ACK,
    state: data.state,
    taskId: data.taskId
})

export const forceStartLavSuccess = (responseData, taskRID) => ({
    type: LAV_FORCE_START_SUCCESS,
    payload: responseData,
    taskRID
});

export const forceStartLavError = () => ({
    type: LAV_FORCE_START_ERROR
});

export const completeTaskDataAction = (postData) => ({
    type: COMPLETE_TASK_ACTION,
    userId: postData.userId,
    phoneNumber: postData.phoneNumber,
    workArea: postData.workArea,
    stationCode: postData.stationCode,
    companyCode: postData.companyCode,
    taskCompleteData: postData.taskCompleteData
});

export const completeTaskDataSucccess = (responseData) => ({
    type: COMPLETE_TASK_SUCCESS,
    payload: responseData
});

export const completeTaskDataSucccess2 = () => ({
    type: COMPLETE_TASK_SUCCESS2,
});

export const forceStartLavErrorRemove = () => ({
    type: LAV_FORCE_START_ERROR_REMOVE
});

export const listenLogout = () => ({
    type: PERFORM_LOGOUT
});

export const fetchTaskByTimelineAction = (postData) => ({
    type: FETCH_TASK_TIMELINE_ACTION,
    userId: postData.userId,
    employeeId: postData.employeeId,
    phoneNumber: postData.phoneNumber,
    stationCode: postData.stationCode,
    companyCode: postData.companyCode,
    shiftId: postData.shiftId
});

export const fetchTaskByTimelineSuccess = (responseData) => ({
    type: FETCH_TASK_TIMELINE_SUCCESS,
    payload: responseData
});

export const fetchTaskByTimelineError = () => ({
    type: FETCH_TASK_TIMELINE_ERROR
});

export const listenLogin=()=>({
    type:LOGIN_AUTH
})

export const postTaskAckDataError = () => ({
    type: POST_TASK_ACT_DATA_ERROR
});

export const completeTaskDataError = () => ({
    type: COMPLETE_TASK_DATA_ERROR
});

export const deleteTaskAckDataError = () => ({
    type: DELETE_TASK_ACK_DATA_ERROR
});

export const broadcastMessageAck = (userId, phoneNumber, stationCode, companyCode, taskAckList, alertAckList, messageType) => ({
    type: BROADCAST_ACK,
    userId,
    phoneNumber,
    stationCode,
    companyCode,
    taskAckList,
    alertAckList,
    messageType
});

export const broadcastMessageAckSuccess = (response) => ({
    type: BROADCAST_ACK_SUCCESS,
    data: response
});

export const occurenceMessageAckSuccess = (response) => ({
    type: OCCURENCE_ACK_SUCCESS,
    data: response
});

export const clearNotifyonAckAsAnotherUser = () => ({
    type: CLEAR_BC_AND_TASK
});

export const spinnerOnCompleteTask = () => ({
    type: ADD_SPINNER
});

export const spinnerOnCompleteTaskError = () => ({
    type: REMOVE_SPINNER
});

export const emptyshiftandtaskdata = () => ({
    type: EMPTY_TASK_AND_SHIFT_DATA
});

export const changeTaskBarToHome = () => ({
    type: CHANGE_TASK_BAR_TO_HOME
});

export const changeTaskBarToProfile = () => ({
    type: CHANGE_TASK_BAR_TO_PROFILE
});

export const createAdhocTask = (userId, station, shiftId) => ({
    type: ADHOC_TASK_CREATE,
    userId,
    station,
    shiftId 
});

export const createAdhocTaskSuccess = () => ({
    type: ADHOC_TASK_CREATE_SUCCESS
});

export const createAdhocTaskFailure = () => ({
    type: ADHOC_TASK_CREATE_ERROR
});

export const openTourOnLogIn = (response) => ({
    type: OPEN_TOUR_ON_LOGIN,
    payload: response
})

export const closeTourPopup = (response) => ({
    type: CLOSE_TOUR_POPUP,
    payload: response
})

export const completeTourMode = (response) => {
    return {
    type: TOUR_MODE_COMPLETED,
    userId: response.userId,
    stationCode: response.stationCode,
    companyCode: response.companyCode,
    }
}

export const showAdhocResponseModal = erroractions.showErrorAction

// export const showDefaultErrorUi = (data) => {
//     return {
//         type: "SHOW_DEFAULT_ERROR_UI",
//         err: {...data}
//     }
// }