import {call, put, delay } from 'redux-saga/effects';
import * as actions from '../../modules/shift/shiftAction';
import { getSubWorkArea, getShiftsList, getTeamsList } from '../../utils';
import * as erroractions from '../../modules/error/ErrorAction';
import { commonError } from '../../modules/error/ErrorCommonFunction';

export function* fetchSubWorkAreaList(data) {
    for(let retryCount = 0; retryCount < 3; retryCount++) {
        try {
            const response = yield call(getSubWorkArea, { 
                stationCode: data.stationCode, 
                department: data.department, 
                workAreas: data.workAreas 
            });
            response.data.errorResponseDTO.errorCode === 0 ?
                yield put(actions.getSubWorkAreasSuccess(response.data))
                :
                yield put(erroractions.showErrorAction(response.data.errorResponseDTO))
                break;
        }
        catch(error) {
            if(retryCount < 2 && (JSON.stringify(error.code) === '"ECONNABORTED"' || JSON.stringify(error.message) === '"Network Error"')) {
                yield delay(5000);
            } else {
                commonError(error);
                break;
            }
        }
    }
}

export function* fetchShiftsList(data) {
    for(let retryCount = 0; retryCount < 3; retryCount++) {
        try {
            const response = yield call(getShiftsList, { 
                stationCode: data.stationCode, 
                department: data.department, 
                workAreas: data.workAreas,
                subWorkAreas : data.subWorkAreas,
                teams: data.teams 
            });
            response.data.errorResponseDTO.errorCode === 0 ?
                yield put(actions.getShiftSuccess(response.data))
                :
                yield put(erroractions.showErrorAction(response.data.errorResponseDTO))
                break;
        }
        catch(error) {
            if(retryCount < 2 && (JSON.stringify(error.code) === '"ECONNABORTED"' || JSON.stringify(error.message) === '"Network Error"')) {
                yield delay(5000);
            } else {
                commonError(error);
                break;
            }
        }
    }
}

export function* fetchTeamsList(data) {
    for(let retryCount = 0; retryCount < 3; retryCount++) {
        try {
            const response = yield call(getTeamsList, { 
                stationCode: data.stationCode, 
                department: data.department, 
                subWorkAreas : data.subWorkAreas 
            });
            response.data.errorResponseDTO.errorCode === 0 ?
                yield put(actions.getTeamsSuccess(response.data))
                :
                yield put(erroractions.showErrorAction(response.data.errorResponseDTO))
                break;
        }
        catch(error) {
            if(retryCount < 2 && (JSON.stringify(error.code) === '"ECONNABORTED"' || JSON.stringify(error.message) === '"Network Error"')) {
                yield delay(5000);
            } else {
                commonError(error);
                break;
            }
        }
    }
}