import { lazy } from "react";
import LearnRovrModal from "../common/components/tour/LearnRovrModal";
import TourModeScreen from "../common/components/tour/TourModescreen";
const Scorecard = lazy(() => import("../modules/scorecard/ScorecardContainer"));
const FeedBackContainer = lazy(() =>
  import("../modules/feed-back/FeedBackContainer")
);
const FaqsContainer = lazy(() => import("../modules/faqs/FaqsContainer"));
const TimelineContainer = lazy(() =>
  import("../modules/shift/TimelineContainer")
);
const TaskContainer = lazy(() => import("../modules/task/TaskContainer"));
const ShiftContainer = lazy(() => import("../modules/shift/ShiftContainer"));
const AuthenticationContainer = lazy(() =>
  import("../modules/authentication/AuthenticationContainer")
);
const ScorecardDashboard = lazy(() =>
  import("../modules/scorecardDashboard/SCDashboardComponent")
);
const NotifyContainer = lazy(() =>
  import("../modules/notification/NotificationContainer")
);
const FeedBackThanks = lazy(() =>
  import("../modules/feed-back/FeedBackThanks")
);
const ChangeMyStationComponent = lazy(() =>
  import("../modules/change-my-station/ChangeMyStationComponent")
);
const DownloadMobileComponent = lazy(() =>
  import("../modules/downloadMobile/DownloadMobile")
);
const SearchPreferenceContainer = lazy(() =>
  import("../modules/search/SearchPreference")
);
const TaskHistoryContainer = lazy(() =>
  import("../modules/taskHistoryModule/TaskHistoryContainer")
);
const routes = [
  {
    path: "/",
    exact: true,
    component: AuthenticationContainer,
    id: 1,
  },
  {
    path: "/task",
    exact: true,
    component: TaskContainer,
    id: 2,
  },
  {
    path: "/shift",
    exact: true,
    component: ShiftContainer,
    id: 3,
  },
  {
    path: "/timeline",
    exact: true,
    component: TimelineContainer,
    id: 4,
  },
  {
    path: "/notification",
    exact: true,
    component: NotifyContainer,
    id: 5,
  },
  {
    path: "/feedback",
    exact: true,
    component: FeedBackContainer,
    id: 6,
  },
  {
    path: "/faqs",
    exact: true,
    component: FaqsContainer,
    id: 7,
  },
  {
    path: "/feedbacksuccess",
    exact: true,
    component: FeedBackThanks,
    id: 8,
  },
  {
    path: "/tasksearch",
    exact: true,
    component: TaskContainer,
    id: 9,
  },
  {
    path: "/changemystation",
    exact: true,
    component: ChangeMyStationComponent,
    id: 10,
  },
  {
    path: "/downloadMobile",
    exact: true,
    component: DownloadMobileComponent,
    id: 11,
  },
  {
    path: "/search-defaults",
    exact: true,
    component: SearchPreferenceContainer,
    id: 12,
  },
  {
    path: "/taskHistory",
    exact: true,
    component: TaskHistoryContainer,
    id: 13,
  },
  {
    path: "/tourMode",
    exact: true,
    component: TourModeScreen,
    id: 14,
  },
  {
    path: "/scorecard",
    exact: true,
    component: Scorecard,
    id: 15,
  },
  {
    path: "/scorecardDashboard",
    exact: true,
    component: ScorecardDashboard,
    id: 16,
  },
];

export default routes;
