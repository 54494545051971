import {
  TASK_HISTORY_SEARCH,
  TASK_HISTORY_SEARCH_SUCCESS,
  TASK_HISTORY_SEARCH_ERROR,
  CLEAR_TASK_HISTORY_SEARCH
} from "./taskHistoryConstant.js";

export const getTaskHistorySearch = (data) => {
  return {
    type: TASK_HISTORY_SEARCH,
    data: data,
  };
};

export const getTaskHistorySearchSuccess = (data) => ({
  type: TASK_HISTORY_SEARCH_SUCCESS,
  data: data
});

export const getTaskHistorySearchError = () => ({
  type: TASK_HISTORY_SEARCH_ERROR,
});

export const clearTaskSearchHistory = () => ({
    type: CLEAR_TASK_HISTORY_SEARCH
});