import React, {useEffect} from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import configureStore from '../store';
import History from '../store/history';
import RouteComponent from '../routes/RouteComponent';
import ErrorContainer from "../modules/error/ErrorContainer";
import resources from "../utils/resources/resources.json"

export const {store}  = configureStore();
window.dynatraceUserId = ""

function App() {
  useEffect(()=> {
    if (resources.DynatraceBeaconURL) {
      const script = document.createElement('script');
      script.src = resources.DynatraceBeaconURL;
      script.crossorigin = "anonymous";
      script.type = "text/javascript";

      document.head.appendChild(script);
    }
  }, []);

  return (
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <div className="App" data-testid="app-component">
        <Router history={History}>      
          <RouteComponent history={History}/>
        </Router>
        <ErrorContainer />
      </div>
      {/* </PersistGate> */}
    </Provider>
  );
}

export default App;