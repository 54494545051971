import {NOTIFICATION_MESSAGES, NOTIFICATION_MESSAGES_SUCCESS} from './NotificationConstant';


export const notificationMessages = (userId,stationCode,shiftId) => ({
    type: NOTIFICATION_MESSAGES,
    userId,
    stationCode,
    shiftId
});

export const notificationMessagesSuccess = (response) => ({
    type: NOTIFICATION_MESSAGES_SUCCESS,
    data: response
});