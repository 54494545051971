import axios from 'axios';
import * as ApiEndPoints from './ApiEndPoints';

const instance = axios.create({
    baseURL: ApiEndPoints.BASE_URL
});

// instance.defaults.headers.common['Authorization'] = "Bearer "+ AUTH_TOKEN;
// instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// instance.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.timeout = 30000;

export default instance;