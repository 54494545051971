import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  crewStyles,
  crewBlankStyles,
} from "../../common/components/crewstyles/CrewStyles";
import ReactTooltip from "react-tooltip";
import CustomerIconBlue from "../../assets/images/Web_Customer_Icon_Blue.svg";
import CustomerIconOrange from "../../assets/images/Web_Customer_Icon_Orange.svg";
import CustomerIconGreen from "../../assets/images/Web_Customer_Icon_Green.svg";
import CustomerIconGray from "../../assets/images/Web_Customer_Icon_Gray.svg";
import CustomerIconTlink from "../../assets/images/Web_Customer_Icon_Tlink.svg";

function getCustomerIcon(status, tlink) {
  if (status === "ASSIGNED") {
    return `url(${CustomerIconBlue})`;
  } else if (status === "CONFIRMED" || status === "ROVR_CONFIRMED") {
    return `url(${CustomerIconOrange})`;
  } else if (tlink) {
    return `url(${CustomerIconTlink})`;
  } else if (status === "STARTED") {
    return `url(${CustomerIconGreen})`;
  } else if (status === "FINISHED") {
    return `url(${CustomerIconGray})`;
  }
}

function CrewComponent({
  crewList = [],
  crewInfo,
  empName,
  showNameFlag,
  isCrewChief,
  role,
  shiftInfoList,
}) {
  
  const adminFlag =
    role === "Rovr_RT_Adm" ||
    role === "Rovr_Admin" ||
    role === "Rovr_Super_Admin";
  const tableData = crewList.map((item) => {
    if (item.firstName && item.lastName) {
      const ellipses = item.lastName.length < 8 ? "" : "...";
      const name =
        item.firstName.charAt(0) + "." + item.lastName.slice(0, 8) + ellipses;
      const fullName = item.firstName + " " + item.lastName;
      // const toShowUnackName =
      //   adminFlag ||
      //   (shiftInfoList != null && shiftInfoList.showUnAckNameOnSearch == true);
      
      return {
        // 'Name': item.ackRequired ? 'Unacknow...' : name,
        // 'FullName': item.ackRequired ? 'Unacknowledged' : fullName,
        Name: adminFlag
          ? name
          : item.ackRequired && !showNameFlag
          ? "Assigned"
          : name,
        FullName: adminFlag? fullName : item.ackRequired && !showNameFlag ? "Assigned" : fullName,
        Task: item.taskName,
        id: item.userId + Math.floor(Math.random() * 100),
        StartTime: item.startTime,
        TaskStatus: item.taskStatus,
        TLinkRoute: item.tlinkRoute,
        taskRole: item.taskRole,
        taskRole2: item.taskRole2,
        taskRoleEligible: item.taskRoleEligible,
      };
    } else {
      // const TaskStartTime = moment(item.startTime).format("HH:mm");
      return {
        Name: item.lastName,
        Task: item.taskName,
        StartTime: item.startTime,
        TaskStatus: item.taskStatus,
        TLinkRoute: item.tlinkRoute,
        taskRole: item.taskRole,
        taskRole2: item.taskRole2,
        taskRoleEligible: item.taskRoleEligible,
      };
    }
  });

  const getRole = (taskRole, taskRole2, taskRoleEligible) => {
    if (taskRoleEligible) {
      if (taskRole != null && taskRole2 != null) {
        return (
          <span style={{ color: "blue", fontSize: "12px" }} info="">
            {" "}
            ({taskRole}  -  {taskRole2})
          </span>
        );
      } else if (taskRole != null) {
        return (
          <span style={{ color: "blue", fontSize: "12px" }} info="">
            {" "}
            ({taskRole})
          </span>
        );
      } else if (taskRole2 != null) {
        return (
          <span style={{ color: "blue", fontSize: "12px" }} info="">
            {" "}
            ({taskRole2})
          </span>
        );
      } else {
        return (
          <b
            style={{
              color: "blue",
              fontSize: "12px",
              fontStyle: "bold",
            }}
          >
            {" ( - )"}
          </b>
        );
      }
    }
  };

  const columns =
    crewInfo === "care"
      ? [
          {
            name: "Customer Care",
            selector: "Name",
            sortable: false,
            cell: (row) => {
              return (
                <div>
                  <div
                    style={{
                      backgroundImage: getCustomerIcon(
                        row.TaskStatus,
                        row.TLinkRoute
                      ),
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "left center",
                      paddingLeft: "25px",
                    }}
                    data-tip={row.FullName}
                    data-for={row.id}
                  >
                    {row.Name}
                  </div>
                  <ReactTooltip id={row.id} place="right" />
                </div>
              );
            },
          },
          {
            name: "Task",
            selector: "Task",
            sortable: false,
          },
          {
            name: "Start Time",
            selector: "StartTime",
            sortable: false,
          },
        ]
      : [
          {
            name: "Customer Operations",
            selector: "Name",
            sortable: false,
            cell: (row) => {
              return (
                <div>
                  <div
                    style={{
                      backgroundImage: getCustomerIcon(
                        row.TaskStatus,
                        row.TLinkRoute
                      ),
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "left center",
                      paddingLeft: "25px",
                    }}
                    data-tip={row.FullName}
                    data-for={row.id}
                  >
                    {row.Name}
                    {getRole(row.taskRole, row.taskRole2, row.taskRoleEligible)}
                  </div>
                  <ReactTooltip id={row.id} place="right" />
                </div>
              );
            },
          },
          {
            name: "Task",
            selector: "Task",
            sortable: false,
          },
          {
            name: "Start Time",
            selector: "StartTime",
            sortable: false,
          },
        ];

  return (
    <div>
      {tableData.length > 0 ? (
        <DataTable
          columns={columns}
          customStyles={crewStyles}
          data={tableData}
          noHeader
          fixedHeader
          persistTableHead
        />
      ) : (
        <DataTable
          columns={columns}
          customStyles={crewBlankStyles}
          data={[{ Name: "...", Task: "...", StartTime: "..." }]}
          noHeader
          fixedHeader
          persistTableHead
        />
      )}
    </div>
  );
}

export default CrewComponent;
