import {
    FETCH_SHIFT_DATA,
    FETCH_SHIFT_DATA_SUCCESS,
    FETCH_SHIFT_DATA_FAILURE,
    FETCH_SUBWORKAREA_DATA,
    FETCH_SUBWORKAREA_DATA_SUCCESS,
    FETCH_SUBWORKAREA_DATA_FAILURE,
    CLEAR_SHIFT_SEARCH,
    SHIFT_XHR,
    TEAMS_XHR,
    FETCH_TEAMS_DATA,
    FETCH_TEAMS_DATA_SUCCESS,
    FETCH_TEAMS_DATA_FAILURE,
} from './shiftConstant';

export const getTeamsList = (stationCode, department, subWorkAreas) => {
    return {
        type: FETCH_TEAMS_DATA,
        stationCode,
        department,
        subWorkAreas
    }
}

export const getTeamsSuccess = (data) => {
    return {
        type: FETCH_TEAMS_DATA_SUCCESS,
        response: data
    }
}

export const getTeamsFailure = (data) => {
    return {
        type: FETCH_TEAMS_DATA_FAILURE,
        response: data
    }
}

export const getShift = (stationCode, department, workAreas, subWorkAreas, teams=[]) => {
    return {
        type: FETCH_SHIFT_DATA,
        stationCode,
        department,
        workAreas,
        subWorkAreas,
        teams
    }
};

export const getShiftSuccess = (data) => {
    return {
        type: FETCH_SHIFT_DATA_SUCCESS,
        response: data
    }
};


export const getShiftFailure = (data) => {
    return {
        type: FETCH_SHIFT_DATA_FAILURE,
        response: data
    }
};

// export const showShiftLoader = (data) => {
//     return {
//         type: SHOW_SHIFT_LOADER,
//         response : data        
//     }
// }

export const getSubWorkAreas = (stationCode, department, workAreas) => {
    return {
        type: FETCH_SUBWORKAREA_DATA,
        stationCode,
        department,
        workAreas
    }
};

export const getSubWorkAreasSuccess = (data) => {
    return {
        type: FETCH_SUBWORKAREA_DATA_SUCCESS,
        response: data
    }
};

export const getSubWorkAreasFailure = (data) => {
    return {
        type: FETCH_SUBWORKAREA_DATA_FAILURE,
        response: data
    }
};

export const clearShiftSearch = () => {
    return {
        type : CLEAR_SHIFT_SEARCH
    }
}

export const turnInitialShiftXhr = (flag = false) => {
    return {
        type: SHIFT_XHR,
        response: flag
    }
}

export const turnInitialTeamsXhr = (flag = false) => {
    return {
        type: TEAMS_XHR,
        response: flag
    }
}