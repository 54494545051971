import { 
    FETCH_SHIFT_DATA,
    FETCH_SHIFT_DATA_FAILURE,
    FETCH_SHIFT_DATA_SUCCESS,
    FETCH_SUBWORKAREA_DATA,
    FETCH_SUBWORKAREA_DATA_FAILURE,
    FETCH_SUBWORKAREA_DATA_SUCCESS,
    CLEAR_SHIFT_SEARCH,
    FETCH_TEAMS_DATA,
    FETCH_TEAMS_DATA_SUCCESS,
    FETCH_TEAMS_DATA_FAILURE,
    SHIFT_XHR,
    TEAMS_XHR
} from './shiftConstant';

const initialState = {
    baseTeamsList: [],
    teamsList: [],
    teamsLoading:false,
    shiftDataList: [],
    subWorkAreaList: [],
    shiftDataLoading: false,
    subWorkAreaLoading: false,
    shiftInitialXhr: false,
    teamsInitialXhr: false
}

function shiftReducer(state = initialState, action) {
	
    switch(action.type) {
        case FETCH_SUBWORKAREA_DATA: {
            return {
                ...state,
                subWorkAreaLoading: true
            }
        }
        case FETCH_SUBWORKAREA_DATA_SUCCESS: {
            return {
                ...state,
                subWorkAreaList: action.response.subworkAreas,
                baseTeamsList: action.response.teams,
                subWorkAreaLoading: false,
                // shiftInitialXhr: true
            }
        }
        case FETCH_SUBWORKAREA_DATA_FAILURE: {
            return {
                ...state,
                subWorkAreaLoading: false
            }
        }
        case FETCH_SHIFT_DATA_SUCCESS: {
            return {
                ...state,
                shiftDataList: action.response,
                shiftDataLoading: false,
            }
        }
        case FETCH_SHIFT_DATA_FAILURE: {
            return {
                ...state,
                shiftDataList: [],
                shiftDataLoading: false,
            }
        }
        case FETCH_SHIFT_DATA: {
            return {
                ...state,
                shiftDataLoading: true
            }
        }
        case CLEAR_SHIFT_SEARCH : {
            return {
                ...state,
                shiftDataList: [],
                subWorkAreaList: []
            }
        }
        case SHIFT_XHR: {
            return {
                ...state,
                shiftInitialXhr: action.response,
            }
        }
        case TEAMS_XHR : {
            return {
                ...state,
                teamsInitialXhr: action.response
            }
        }
        case FETCH_TEAMS_DATA : {
            return {
                ...state,
                teamsLoading: true
            }
        }
        case FETCH_TEAMS_DATA_SUCCESS : {
            return {
                ...state,
                teamsLoading: false,
                teamsList: action.response.teams
            }
        }
        case FETCH_TEAMS_DATA_FAILURE : {
            return {
                ...state,
                teamsLoading: false,
                teamsList: []
            }
        }
        default: {
            return state;
        }
    }
}

export default shiftReducer;