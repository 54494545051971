import {call, put, delay } from 'redux-saga/effects';
import * as actions from '../../modules/search/searchAction';
import { getSearchDefaultInfo, putSearchDefaultInfo, deleteSearchDefaultInfo } from '../../utils';
import * as erroractions from '../../modules/error/ErrorAction';
import { commonError } from '../../modules/error/ErrorCommonFunction';

export function* fetchSearchDefaultInfo (action) {
    const userId = action.userId;
    for(let retryCount = 0; retryCount < 3; retryCount++) {
        try {
            const response = yield call(getSearchDefaultInfo, userId);
            const respObj = {
                userId: userId,
                stationCode: response.data.stationCode,
                department: response.data.department,
                searchCriteria: response.data.searchCriteria,
                searchValues: response.data.searchValues,
                searchValue: response.data.searchValue,
                searchSubValues: response.data.searchSubValues,
                searchSubValues2: response.data.searchSubValues2
            }
            response.data.errorResponseDTO.errorCode === 0 || response.data.errorResponseDTO.errorCode === 1044 ?
                yield put(actions.getSearchDefaultSuccess(respObj))
                :
                yield put(erroractions.showErrorAction(response.data.errorResponseDTO))
                break;
        }
        catch(error) {
            if(retryCount < 2 && (JSON.stringify(error.code) === '"ECONNABORTED"' || JSON.stringify(error.message) === '"Network Error"')) {
                yield delay(5000);
            } else {
                commonError(error);
                break;
            }
        }
    }
}

export function* storeSearchDefaultInfo(action) {
    for(let retryCount = 0; retryCount < 3; retryCount++) {
        try {
            const reqPayload = {
                userId: action.userId,
                stationCode: action.stationCode,
                department: action.department,
                searchCriteria: action.searchCriteria,
                searchValues: action.searchValues,
                searchValue: action.searchValue,
                searchSubValues: action.searchSubValues,
                searchSubValues2: action.searchSubValues2
            }

            const response = yield call(putSearchDefaultInfo, reqPayload);
            
            // In place of response.data we are giving the previous object as the response data 
            // from server is not returning the actual data of the user
            if(response.data.errorResponseDTO.errorCode === 0) {
                yield put(actions.putSearchDefaultSuccess(reqPayload))
            } else {
                yield put(erroractions.showErrorAction(response.data.errorResponseDTO))
                yield put(actions.putSearchDefaultError(reqPayload))
            }
            break;
        }
        catch(error) {
            if(retryCount < 2 && (JSON.stringify(error.code) === '"ECONNABORTED"' || JSON.stringify(error.message) === '"Network Error"')) {
                yield delay(5000);
            } else {
                commonError(error);
                break;
            }
        }
    }
}

export function* clearSearchDefaultInfo(action) {
    for(let retryCount = 0; retryCount < 3; retryCount++) {
        try {
            const reqPayload = { userId: action.userId }
            const response = yield call(deleteSearchDefaultInfo, reqPayload);
            response.data.errorResponseDTO.errorCode === 0 ?
                yield put(actions.deleteSearchDefaultSuccess())
                :
                yield put(erroractions.showErrorAction(response.data.errorResponseDTO))
                break;
        }
        catch(error) {
            if(retryCount < 2 && (JSON.stringify(error.code) === '"ECONNABORTED"' || JSON.stringify(error.message) === '"Network Error"')) {
                yield delay(5000);
            } else {
                commonError(error);
                break;
            }
        }
    }   
}

export function* showDefaultErr(data) {
    yield put(erroractions.showErrorAction({
        ...data.err
    }))
}