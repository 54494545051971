import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import * as actions from "../modules/authentication/AuthenticationAction";
import { bindActionCreators } from "redux";
import routes from "./RouteConfig";
import AuthenticationComponent from "../modules/authentication/AuthenticationComponent";
import  Spinner from '../common/components/spinner/Spinner';

const FallbackComponent = <Spinner />;

class RouteComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      sessionValid: false,
      // changed: false,
    };
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.decodedToken !== prevState.decodedToken) {
  //     nextProps.action.authenticateSuccess(nextProps.decodedToken);
  //     return { changed: true };
  //   }

  //   return null;
  // }

  componentDidMount() {
    const userData = localStorage.getItem("tokenData");
    const userDetailsJson = JSON.parse(userData);
    if (userDetailsJson && "SM_USER" in userDetailsJson) {
      this.props.action.authenticateSuccess(userDetailsJson);
      this.setState({ sessionValid: true, userData: userDetailsJson });
    }
  }

  getInitialPage=() => {
    if (this.props.decodedToken.amrcompany == "AE" || this.props.gsrtStation) {
      return "/task"
    } else {
      return "/scorecard"
    }
  }

  render() {
    const { history } = this.props;
    return (
      <Suspense fallback={FallbackComponent}>
        {
        this.props.accessToken ? 
        (
          <>
            <Switch>
              {routes.map((route) => (
                <Route
                  path={route.path}
                  component={route.component}
                  exact={route.exact}
                  key={route.id}
                  history={history}
                />
              ))}
            </Switch>
            <Redirect to={this.getInitialPage()}/>
          </>
        ) 
        : (
          <AuthenticationComponent userDetails={this.state.userData} /> // I am logged in 
        )
        }
      </Suspense>
    );
  }
}

const mapStateToProps = (state) => ({
  decodedToken: state.AuthenticationReducer.decodedToken,
  accessToken: state.AuthenticationReducer.accessToken,
  rovrUser: state.AuthenticationReducer.registerData.rovrUser,
  gsrtStation: state.AuthenticationReducer.registerData.gsrtStation,
  companyCode: state.AuthenticationReducer.companyCode,
});

const mapDispatchToProps = (dispatch) => ({
  action: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RouteComponent);
