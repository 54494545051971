import React, { useState, useEffect } from "react";
// import './Popup.scss';
import "./Popup2.scss";
import "./check.scss";
import Modal from "react-bootstrap/Modal";
import CustomerIconBlue from "../../assets/images/Web_Customer_Icon_Blue.svg";
import CustomerIconOrange from "../../assets/images/Web_Customer_Icon_Orange.svg";
import CustomerIconGreen from "../../assets/images/Web_Customer_Icon_Green.svg";
import CustomerIconGray from "../../assets/images/Web_Customer_Icon_Gray.svg";
import CustomerIconTlink from "../../assets/images/Web_Customer_Icon_Tlink.svg";
import MergeRole from '../../assets/images/merge_role.svg';
import SplitRole from '../../assets/images/split_role.svg';
import FlightArrivalIcon from '../../assets/images/Web_FlightDeparture_Icon.svg'
import FlightDepartureIcon from '../../assets/images/Web_FlightArrival_Icon.svg'
/**
 * enable for testing purpose
 */
// import mockFs from "./mockFs";

import dropdownIcon from "../../assets/images/dropdown.svg";
import { isArray } from "lodash";

const SetRoles2 = ({
  modalShowFlag,
  showHideModal,
  fsCrewList,
  superprops,
  action,
  taskRoles,
  updateHandle,
  fetchDetailsParams,
  callForDetailsAction,
  callForAllUserDetails,
  setRolesCount,
  allowedTaskRoles
}) => {


  const [selectedRole, setSelectedRole] = useState({});
  const [roles, setRoles] = useState([]);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [rolesApiHitCnt, setRolesApiHitCnt] = useState(setRolesCount);
  const [rolesErr, setRolesErr] = useState(false);
  const [roles2Err, setRoles2Err] = useState(false);
  const [isRoleSplit, setIsRoleSplit] = useState(true);
  const [isTaskRoleReset, setTaskRoleReset] = useState(false)
  const [isSaveEnabled, setIsSaveEnabled] = useState(true);

  useEffect(() => {
    getRoleIconStatus()
    if(allowedTaskRoles != null && isArray(allowedTaskRoles)) {
      let rolesArr = allowedTaskRoles.map((elem) => {
        return {
          label: elem.toUpperCase(),
          value: elem.toLowerCase(),
        };
      });
      setRoles(rolesArr);
    } else {
      setRoles([])
    }
    if (fsCrewList == null) {
      setSelectedRole({});
    } else {
      let obj = {};
      fsCrewList.map((elem) => {
        const key = getKey(elem);
        obj[key] = {
          value: elem.taskRole,
          value2: elem.taskRole2,
        };
      });
      setSelectedRole(obj);
      setRolesErr(false);
    }
  }, [])

  useEffect(() => {
   
  }, [fsCrewList]);

  useEffect(() => {
    if (setRolesCount == rolesApiHitCnt) {
      return;
    }
    if (setRolesCount > 0 && rolesApiHitCnt == setRolesCount) {
      setRolesApiHitCnt(setRolesCount);
      return;
    }
    setDisplaySuccess(true);
    callForDetailsAction();
    callForAllUserDetails();
    setRolesApiHitCnt(setRolesCount);
  }, [setRolesCount]);

  useEffect(() => {
    if (displaySuccess == true) {
      setTimeout(() => {
        setDisplaySuccess(false);
        handleClose();
      }, 1000);
    }
  }, [displaySuccess]);

  const handleClose = () => {
    setSelectedRole({});
    showHideModal(false);
  };

  const selectHandler = (event, key) => {
    let selectedLength = []
    let optionData = event.target.value;
    let isSaveButtonEnabled = true
    Object.keys(selectedRole).map((key1, index) => {
      if(key1 === key) {
        selectedRole[key].value = optionData === "Roles" ? null : optionData
      }
    });
     Object.keys(selectedRole).map((key2, index) => {
     if(selectedRole[key2].value != null) {
      selectedLength.push(selectedRole[key2].value)
     }
    });
    if(!isRoleSplit){
    handlerSaveButton()
    }
    if(selectedLength.length > 0) {
    const duplicates = selectedLength.filter((item, index) => index !== selectedLength.indexOf(item));
    setRolesErr(duplicates.length > 0);
    }
  };

  const selectHandler2 = (event, key) => {
    let selectedLength = []
    let optionData = event.target.value;
 
    Object.keys(selectedRole).map((key1, index) => {
      if(key1 === key) {
        selectedRole[key].value2 = optionData === "Roles" ? null : optionData
      }
    });
    Object.keys(selectedRole).map((key2, index) => {
     if(selectedRole[key2].value2 != null){
      selectedLength.push(selectedRole[key2].value2)
     }
    });
    handlerSaveButton()
    if(selectedLength.length > 0) {
    const duplicates = selectedLength.filter((item, index) => index !== selectedLength.indexOf(item));
    setRoles2Err(duplicates.length > 0);
    }
  };

  const handlerSaveButton = () => {
    let isSaveButtonDisabled = false

    Object.keys(selectedRole).map((key3, index) => {
      if (selectedRole[key3].value != null && selectedRole[key3].value2 == null) {
        isSaveButtonDisabled = true
      } else  if (selectedRole[key3].value == null && selectedRole[key3].value2 != null) {
        isSaveButtonDisabled = true
      }
    });
    setIsSaveEnabled(isSaveButtonDisabled)
  }
  const handleSaveData = () => {
    let roles = [];
    Object.keys(selectedRole).map((key, index) => {
      const ob = splitKey(key);
      const roleValue = selectedRole[key].value;
      const roleValue2 = selectedRole[key].value2;
      roles.push({
        empId: ob.userId,
        taskId: ob.taskId,
        role: roleValue,
        role2: roleValue2,
      });
    });
      const data = {
        userId: superprops.decodedToken.SM_USER,
        stationCode: superprops.decodedToken.SM_STATION,
        companyCode: superprops.decodedToken.amrcompany,
        roles: roles,
        fetchDetailsParams: fetchDetailsParams,
      };
      action.updateTaskRolesAction(data);
    
  };

  const splitKey = (key) => {
    const a = key.split("::");
    return {
      userId: a[0],
      taskId: a[1],
    };
  };

  const getKey = (elem) => {
    return String(elem.userId + "::" + elem.taskId);
  };

  const getCustomerIcon = (status, tlink) => {
    if (status === "ASSIGNED") {
      return CustomerIconBlue;
    } else if (status === "CONFIRMED" || status === "ROVR_CONFIRMED") {
      return CustomerIconOrange;
    } else if (tlink) {
      return CustomerIconTlink;
    } else if (status === "STARTED") {
      return CustomerIconGreen;
    } else if (status === "FINISHED") {
      return CustomerIconGray;
    }
  };
  const setRoleStatusOnClick = () => {
    resetSelectedRoles()
    setIsRoleSplit(!isRoleSplit)
    setTaskRoleReset(true)
    setIsSaveEnabled(false)
    setRolesErr(false);
    setRoles2Err(false);

  }
  const getRoleIconStatus = () => {
    if(fsCrewList != null && fsCrewList.length > 0){
     fsCrewList.map((item) => {
       if(item.taskRole != null && item.taskRole2 != null){
         setRoleStatus()
       }
     })
     handlerSaveButton()
   }
  }

  const resetSelectedRoles = () => {
    Object.keys(selectedRole).map((key, index) => {
      selectedRole[key].value = null
      selectedRole[key].value2 = null
    });
  }
  const setRoleStatus = () => {
    setIsRoleSplit(!isRoleSplit)
  }

  const showTaskRoleValue = (type, taskRole, taskRole2) => {
    let value = null;
    switch(type){
     case "inbound":   
     if (taskRole != null && taskRole2 != null) {
        value = taskRole;
     } else if (taskRole != null && taskRole2 == null) {
       value = taskRole;
     } else if (taskRole == null && taskRole2 != null) {
       value = taskRole2;
     }
     break
     case "outbound":   
     if (taskRole != null && taskRole2 != null) {
       value = taskRole2;
     } else if (taskRole != null && taskRole2 == null) {
       value = taskRole;
     } else if (taskRole == null && taskRole2 != null) {
       value = taskRole2;
     }
     break
     default: // NOTHING
     }
     return value
    }
  return (
    <Modal
      show={modalShowFlag}
      onHide={handleClose}
      dialogClassName="set-roles-fsa"
    >
      <Modal.Header closeButton>
        <Modal.Title className="aboutHead">
          Roles
          {(rolesErr || roles2Err) && (
            <div className="setRolesErrContainer">
              <span className="setRolesErr">
                <b>*Each role can only be assigned to one agent.</b>
              </span>
            </div>
          )}
          {fsCrewList != null && fsCrewList.length > 0 && roles != null && roles.length > 0 && (
          <img src={(isRoleSplit) ? SplitRole : MergeRole}  style={{ position: 'absolute', right: 45, top: 15}} className="setMergeRoles" alt="Split Role" onClick={setRoleStatusOnClick} />
        )} 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {fsCrewList != null && fsCrewList.length > 0 ? (
          <div>
            <div className="popupRolesContainer">
              {displaySuccess && (
                <div
                  style={{
                    display: "block",
                    backgroundColor: "#14E715",
                    width: "100%",
                    borderRadius: "5px",
                    padding: "4px",
                    color: "#36495a",
                    textAlign: "center",
                    marginBottom: 5
                  }}
                >
                  Roles Assigned!
                </div>
              )}
              {!isRoleSplit && roles != null && roles.length > 0 && (
              <div style={{height: 30, flex: 1, flexDirection: 'row', display: 'flex', justifyContent: 'space-between',}}>
              <img src={FlightDepartureIcon}  style={{position: 'absolute', right: 145, marginTop: 5, }} className="setMergeRoles" alt="Split Role" onClick={setRoleStatusOnClick} />
              <img src={FlightArrivalIcon}  style={{position: 'absolute', right: 35, marginBottom: 10,}} className="setMergeRoles" alt="Split Role" onClick={setRoleStatusOnClick} />

              </div>
              )}
              {fsCrewList.map((el, key) => {
                const userIconShowable = getCustomerIcon(
                  el.taskStatus,
                  el.tlinkRoute
                );
                const username = el.firstName + " " + el.lastName;
                const userKey = getKey(el);

                const isFinished = el.taskStatus === "FINISHED";
                
                return (
                  <div key={key} className="eachRoleContainer" style={{}}>
                     <div
                      className="eachRoleUserImage"
                    >
                      <img className="popupUicon" src={userIconShowable} />
                    </div>
                    <div className="eachRoleUserName" style={{marginRight: 15}}>{username}</div>
                     {isRoleSplit && roles != null && roles.length > 0 && (
                      <div style={{}}>
                        <select
                          className="form-control"
                          onChange={(e) => selectHandler(e, userKey)}
                          defaultValue={!isTaskRoleReset ? el.taskRole : null}
                            size="small"
                          style={{
                            marginRight: 20,
                            width: "25%",
                            position: "absolute",
                            right: 0,
                          }}
                        >
                          <option value={null}>Roles</option>
                          {roles.map((r, k) => {
                            return (
                              <option value={r.label} key={k}>
                                {r.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    {!isRoleSplit && roles != null && roles.length > 0 && (
                      <div
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <select
                          className="form-control"
                          onChange={(e) => selectHandler(e, userKey)}
                          defaultValue={!isTaskRoleReset ? el.taskRole : null}
                          as="select"
                          size="small"
                          style={{ flex: 0.5 }}
                        >
                          <option value={null}>Roles</option>
                          {roles.map((r, k) => {
                            return (
                              <option value={r.label} key={k}>
                                {r.label}
                              </option>
                            );
                          })}
                        </select>
                        <select
                          className="form-control"
                          onChange={(e) => selectHandler2(e, userKey)}
                          defaultValue={!isTaskRoleReset ? el.taskRole2 : null}
                          as="select"
                          size="small"
                          style={{ flex: 0.5, marginLeft: 5 }}
                        >
                          <option value={null}>Roles</option>
                          {roles.map((r, k) => {
                            return (
                              <option value={r.label} key={k}>
                                {r.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="roles-popup-button-section">
              <button className="btn roles-cancel" onClick={handleClose}>
                Cancel
              </button>
              <button
                className="btn roles-save"
                disabled={rolesErr || roles2Err || isSaveEnabled}
                onClick={handleSaveData}
              >
                Save
              </button>
            </div>
          </div>
        ) : (
          <span style={{ margin: "10px", fontSize: "15px" }}>
            Sorry! None of the crew members are eligible for assigning roles.
          </span>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default SetRoles2;