import {
  TASK_HISTORY_SEARCH,
  TASK_HISTORY_SEARCH_SUCCESS,
  TASK_HISTORY_SEARCH_ERROR,
	CLEAR_TASK_HISTORY_SEARCH
} from "./taskHistoryConstant.js";

const initialState = {
  taskHistoryResults: null,
	taskHistoryLoading: false
};

function taskHistoryReducer(state = initialState, action) {
  switch (action.type) {
		case TASK_HISTORY_SEARCH: {
      return {
        ...state,
				taskHistoryLoading: true
      };
    }
    case TASK_HISTORY_SEARCH_SUCCESS: {
      return {
        ...state,
        taskHistoryResults: action.data == null ? [] : action.data,
				taskHistoryLoading: false
      };
    }
    case TASK_HISTORY_SEARCH_ERROR: {
      return {
        ...state,
        taskHistoryResults: [],
				taskHistoryLoading: false
      };
    }
		case CLEAR_TASK_HISTORY_SEARCH : {
			return {
        ...state,
        taskHistoryResults: [],
				taskHistoryLoading: false
      };
		}
		default: 
			return {
				...state
			}
  }
}

export default taskHistoryReducer;