import axios from 'axios';
import * as ApiEndPoints from './ApiEndPoints';

const azureInstance = axios.create({
    baseURL: ApiEndPoints.AZURE_BASE_URL
});


//azureInstance.defaults.headers['Access-Control-Allow-Origin'] = '*';
azureInstance.defaults.headers["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept";
azureInstance.defaults.headers['Access-Control-Allow-Methods'] = "GET, POST, PATCH, PUT, DELETE, OPTIONS'";

azureInstance.defaults.headers.post['Content-Type'] = 'application/json';
azureInstance.defaults.timeout = 30000;

azureInstance.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(error);
});
 
export default azureInstance;
