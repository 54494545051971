import React, { useState } from 'react';
import { Button, Modal, ModalTitle } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'; 
import * as taskactions from '../../../modules/task/taskAndShiftAction';
import {Steps} from './Steps';
import {TourSteps} from './tourSteps';
import { useHistory } from "react-router-dom";

const ExitTourModal = (props) => {
    const history = useHistory();

    const closeModal = () => {
        props.setShowExitTour(false)
        history.push('/');
    }

    const goBackToTour = () =>{
        props.setShowExitTour(false)
    }
    return (
        <>
            <Modal dialogClassName="fullscreenmodal"
                show={props.showExitTour}
                backdrop="static"
                keyboard={false}
                id="about"
            >
                <Modal.Header>
                    <Modal.Title className="aboutHead">Exit Tour</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                   
                    <ModalTitle className="titleBody stepBody">
                        Do you want to exit the tour?
                        </ModalTitle>
                   
                </Modal.Body>
                <div className='stepTourButtons'>
                    <Button className="mr-2 buttonSearch submitColor buttonTour"
                    onClick={goBackToTour}>
                        Back
                    </Button>
                    <Button className="mr-2 buttonSearch resetColor buttonTour"
                    onClick={closeModal}>
                        Exit
                    </Button>
                </div>
            </Modal>
        </>
    )
}

const mapDispatchToState = (state) => ({
    userId: state.AuthenticationReducer.userId,
    decodedToken: state.AuthenticationReducer.decodedToken,
    companyCode: state.AuthenticationReducer.companyCode
    
  });
  
  const mapDispatchToProps = (dispatch) => ({
      action: bindActionCreators({...taskactions}, dispatch)
  });
  
  export default connect(
    mapDispatchToState,
    mapDispatchToProps
  )(ExitTourModal);
  