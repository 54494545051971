import {
  TASK_AND_SHIFT_DATA_SUCCESS,
  TASK_ADDED_SUCCESS,
  SET_COMMENT_INDICATOR,
  TASK_DELETED_SUCCESS,
  GSRT_UPDATE_COMPLETE,
  GSRT_UPDATE_START,
  GSRT_UPDATE_ACK,
  LAV_FORCE_START_SUCCESS,
  LAV_FORCE_START_ERROR,
  COMPLETE_TASK_SUCCESS,
  COMPLETE_TASK_SUCCESS2,
  LAV_FORCE_START_ERROR_REMOVE,
  FETCH_TASK_TIMELINE_SUCCESS,
  TASK_AND_SHIFT_DATA_ERROR,
  POST_TASK_ACT_DATA_ERROR,
  COMPLETE_TASK_DATA_ERROR,
  DELETE_TASK_ACK_DATA_ERROR,
  BROADCAST_ACK_SUCCESS,
  OCCURENCE_ACK_SUCCESS,
  CLEAR_BC_AND_TASK,
  ADD_SPINNER,
  REMOVE_SPINNER,
  EMPTY_TASK_AND_SHIFT_DATA,
  CHANGE_TASK_BAR_TO_HOME,
  CHANGE_TASK_BAR_TO_PROFILE,
  ADHOC_TASK_CREATE_SUCCESS,
  ADHOC_TASK_CREATE_ERROR,
  OPEN_TOUR_ON_LOGIN,
  CLOSE_TOUR_POPUP,
  
} from "./taskAndShiftConstant";
import {REHYDRATE} from 'redux-persist/lib/constants';

const initialState = {
  shiftandtaskData: {},
  ackReceipt: {},
  forceStartLav: {},
  forceStartLavError: false,
  timelineData: {},
  taskAndShiftDataError : false,
  apiError:{},
  showSpinner: false,
  selectedTaskBar: {
    value: "home",
    initial: true
  },
  displayRovrTour: null,
  complete: {},
  adhocTaskCreateSuccess: 0,
  openTour: null,
}; 

const taskAndShiftReducer = (state = initialState, action) => {
  switch (action.type) {
    case TASK_AND_SHIFT_DATA_SUCCESS: {
      if(action.payload.statusCode === 0 && action.payload.taskList !== null){
      const clonedshiftandtaskData = action.payload;
      clonedshiftandtaskData.taskList.forEach((task) => 
        task['taskCommentInd'] = task.commentInd);
      return {
        ...state,
        shiftandtaskData: clonedshiftandtaskData,
        };
      } else {
        return {
        ...state,
        shiftandtaskData: action.payload,
        };
      }
    }
    case REHYDRATE: {
      return {
        ...state,
        persistedState:action.payload,
      };
    }
    case SET_COMMENT_INDICATOR: {
      const clonedshiftandtaskData = JSON.parse(JSON.stringify(state.shiftandtaskData));;
      const filterTaskArray = clonedshiftandtaskData.taskList.filter(task => task.taskRID === action.payload.taskRID);
      const filterTask = filterTaskArray[0];
      filterTask.taskCommentInd = action.payload.status
      return {
        ...state,
        shiftandtaskData:{...clonedshiftandtaskData},
      }
    }
    case TASK_ADDED_SUCCESS: {
      if(action.payload.statusCode === 0){
        const clonedshiftandtaskData = JSON.parse(JSON.stringify(state.shiftandtaskData));
        const filterTaskArray = clonedshiftandtaskData.taskList.filter(task => task.taskRID === action.taskRID);
        clonedshiftandtaskData.taskAlerts = clonedshiftandtaskData.taskAlerts.filter(task => task.taskInstanceId !== action.taskInstanceId);
        const filterTask = filterTaskArray[0];
        if(filterTask.taskDisplayName === 'Lavatory') {
          filterTask.taskStatus = 'CONFIRMED'; 
        } else {
          filterTask.taskStatus = 'CONFIRMED';
        }
        filterTask.ackRequired = false;
        return {
          ...state,
          shiftandtaskData: {...clonedshiftandtaskData},
          ackReceipt: action.payload,
        }
      }
      return {
        ...state,
        ackReceipt: action.payload,
      }
    }
    case TASK_DELETED_SUCCESS: {
      if(action.payload.statusCode === 0){
        const clonedshiftandtaskData = JSON.parse(JSON.stringify(state.shiftandtaskData));
        const filterTaskArray = clonedshiftandtaskData.deleteTaskList.filter(task => task.taskRID === action.taskRID);
        const filterTask = filterTaskArray[0];
        clonedshiftandtaskData.taskAlerts = clonedshiftandtaskData.taskAlerts.filter(task => task.taskInstanceId !== action.taskInstanceId);
        // filterTask.taskStatus = 'STARTED';
        filterTask.ackRequired = false;
        return {
          ...state,
          shiftandtaskData: {...clonedshiftandtaskData},
          ackReceipt: action.payload,
        }
      }
      return {
        ...state,
        ackReceipt: action.payload,
      }
      
    }

    case GSRT_UPDATE_COMPLETE:{

      const clonedshiftandtaskData = JSON.parse(JSON.stringify(state.shiftandtaskData));
      const filterTaskArray = clonedshiftandtaskData.taskList.filter(task => task.taskInstanceId === action.taskId);
      const filterTask = filterTaskArray[0];
      filterTask["gsrtUpdatePendingComplete"] = action.state

      return {
        ...state,
        shiftandtaskData: {...clonedshiftandtaskData},
      }
    }

    case GSRT_UPDATE_START:{
      const clonedshiftandtaskData = JSON.parse(JSON.stringify(state.shiftandtaskData));
      const filterTaskArray = clonedshiftandtaskData.taskList.filter(task => task.taskInstanceId === action.taskId);
      const filterTask = filterTaskArray[0];
      filterTask["gsrtUpdatePendingStart"] = action.state

      return {
        ...state,
        shiftandtaskData: {...clonedshiftandtaskData},
      }
    }

    case GSRT_UPDATE_ACK:{
      const clonedshiftandtaskData = JSON.parse(JSON.stringify(state.shiftandtaskData));
      const filterTaskArray = clonedshiftandtaskData.taskList.filter(task => task.taskInstanceId === action.taskId);
      const filterTask = filterTaskArray[0];
      filterTask['gsrtUpdatePendingAck'] = action.state

      return {
        ...state,
        shiftandtaskData: {
            ...clonedshiftandtaskData,
          },
      }
    }

   case LAV_FORCE_START_SUCCESS: {
      if(action.payload.statusCode === 0){
        const clonedshiftandtaskData = JSON.parse(JSON.stringify(state.shiftandtaskData));
        const filterTaskArray = clonedshiftandtaskData.taskList.filter(task => task.taskInstanceId === action.taskRID);
        const filterTask = filterTaskArray[0];
        filterTask.taskStatus = 'STARTED';
        return {
          ...state,
          shiftandtaskData: {...clonedshiftandtaskData},
          forceStartLav: action.payload,
        }
      }
      return {
        ...state,
        forceStartLav: action.payload,
      }
    }
    case LAV_FORCE_START_ERROR: {
      return {
        ...state,
        forceStartLavError: true,
      }
    }
    case TASK_AND_SHIFT_DATA_ERROR: {
      return {
        ...state,
        payload: action.error,
      }
    }

    case COMPLETE_TASK_SUCCESS2: {
      let filterTaskArray = [];
      if(state.complete.taskCompleteProcessList[0].taskStatus === 0){
        const clonedshiftandtaskData = JSON.parse(JSON.stringify(state.shiftandtaskData));
        filterTaskArray = clonedshiftandtaskData.taskList.filter(task => task.taskInstanceId !== state.complete.taskCompleteProcessList[0].smInstanceId);
      }
      return {
        ...state,
        shiftandtaskData: {
          ...state.shiftandtaskData,
          taskList: filterTaskArray
        },
      }
    }

    case COMPLETE_TASK_SUCCESS: {
      return {
        ...state,
        complete: action.payload,
        showSpinner: false
      }
    }

    case LAV_FORCE_START_ERROR_REMOVE: {
      return {
        ...state,
        forceStartLavError: false,
      }
    }
    case POST_TASK_ACT_DATA_ERROR: {
      return {
        ...state,
        apiError: action.payload
      }
    }
    case COMPLETE_TASK_DATA_ERROR: {
      return {
        ...state,
        apiError: action.payload
      }
    }
    case DELETE_TASK_ACK_DATA_ERROR: {
      return {
        ...state,
        apiError: action.payload
      }
    }
    case BROADCAST_ACK_SUCCESS: {
      const clonedshiftandtaskData = JSON.parse(JSON.stringify(state.shiftandtaskData));
      clonedshiftandtaskData.broadCastAlerts.length = 0;
      return {
        ...state,
        shiftandtaskData: {...clonedshiftandtaskData},
      }
    }
    case OCCURENCE_ACK_SUCCESS: {
      const clonedshiftandtaskData = JSON.parse(JSON.stringify(state.shiftandtaskData));
      clonedshiftandtaskData.occurrenceAlerts.length = 0;
      return {
        ...state,
        shiftandtaskData: {...clonedshiftandtaskData},
      }
    }
    case FETCH_TASK_TIMELINE_SUCCESS: {
      return {
        ...state,
        timelineData: action.payload
      }
    }
    case CLEAR_BC_AND_TASK: {
      const clonedshiftandtaskData = JSON.parse(JSON.stringify(state.shiftandtaskData));
      clonedshiftandtaskData.broadCastAlerts.length = 0;
      clonedshiftandtaskData.taskAlerts.length = 0;
      return {
        ...state,
        shiftandtaskData: {...clonedshiftandtaskData},
      }
    }
    case ADD_SPINNER: {
      return {
        ...state,
        showSpinner: true,
      }
    }
    case REMOVE_SPINNER: {
      return {
        ...state,
        showSpinner: false
      }
    }
    case EMPTY_TASK_AND_SHIFT_DATA : {
      return {
        ...state,
        shiftandtaskData: {}
      }
    }
    case CHANGE_TASK_BAR_TO_HOME : {
      return {
        ...state,
        selectedTaskBar: {
          value: "home",
          initial: false
        }
      }
    }
    case CHANGE_TASK_BAR_TO_PROFILE : {
      
      return {
        ...state,
        selectedTaskBar: {
          value: "profile",
          initial: false
        }
      }
    }

    case OPEN_TOUR_ON_LOGIN: {
      if ( action.payload) {
        return {
          ...state,
          displayRovrTour: action.payload,
          openTour: action.payload
        }
      } else {
        return {
          ...state,
          displayRovrTour: action.payload
        }
      }
    }

    case CLOSE_TOUR_POPUP: {
        return {
          ...state,
          openTour: action.payload
        }
    }

    case ADHOC_TASK_CREATE_SUCCESS : {
      return {
        ...state,
        adhocTaskCreateSuccess: state.adhocTaskCreateSuccess + 1
      }
    }
    default: {
      return state;
    }

  }
};

export default taskAndShiftReducer;