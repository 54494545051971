import AxiosConfig from "./config/AxiosConfig";
import * as ApiEndPoints from "./config/ApiEndPoints";
import AzureConfig from "./config/AzureConfig";
import ScorecardConfig from "./config/ScorecardApiConfig";
import { v4 as uuidv4 } from 'uuid';
import selectBaseURL from './baseUrlSelector';
class RovrAPI {
  constructor(name) {
    this.name = name;
  }

  setAuthorizationToken(accessToken="", employeeId="", station, company, azureBaseURL,scorecardBaseURL){
    const baseURL = selectBaseURL(station, company);
    AxiosConfig.defaults.headers.common['Authorization'] = 'Bearer '+accessToken;
    AxiosConfig.defaults.headers.common['X-EMPID'] = employeeId;
    AxiosConfig.defaults.headers.common['X-STATION'] = station;
    AxiosConfig.defaults.headers.common['X-COMPANY'] = company;
    AxiosConfig.defaults.headers.common['X-DEVICETYPE'] = "Desktop";

    AxiosConfig.defaults.baseURL = baseURL;
    AzureConfig.defaults.baseURL = azureBaseURL;
    ScorecardConfig.defaults.headers.common['Authorization'] = 'Bearer '+accessToken;
    ScorecardConfig.defaults.headers.common['X-EMPID'] = employeeId;
    ScorecardConfig.defaults.headers.common['X-STATION'] = station;
    ScorecardConfig.defaults.headers.common['X-COMPANY'] = company;
    ScorecardConfig.defaults.headers.common['X-DEVICETYPE'] = "Desktop";
    ScorecardConfig.defaults.baseURL = scorecardBaseURL;

  }

  getDataForAllUser(userId = "", stationCode = "", phoneNumber = "", companyCode="" ) {
    let adminusrid = ''
      if(localStorage.getItem('userId') !== ''){
        adminusrid = localStorage.getItem('userId')
      }
    return AxiosConfig.post(ApiEndPoints.GET_ALL_DATA_USER, {
      userId: userId,
      stationCode: stationCode,
      phoneNumber: phoneNumber,
      companyCode: companyCode,
      adminUserId: adminusrid,
      notify: [
        {
          messagetype: "BOTH",
        },
      ],
    });
  }

  getCrewInfo(companyCode = "", stationCode = "", flightIds=[], taskId="") {
    let adminusrid = ''
    if(localStorage.getItem('userId') !== ''){
      adminusrid = localStorage.getItem('userId')
    }
    return AxiosConfig.post(ApiEndPoints.CREW_INFO, {
      companyCode: companyCode,
      stationCode: stationCode,
      flightIds: flightIds,
      taskId: taskId,
      adminUserId: adminusrid,
    });
  }

  forceStartLavTask(userId = "", taskInstanceId = "", stationCode = "") {
    let adminusrid = ''
    if(localStorage.getItem('userId') !== ''){
      adminusrid = localStorage.getItem('userId')
    }
    return AxiosConfig.post(ApiEndPoints.START_TASK, {
      userId: userId,
      taskRtId: taskInstanceId,
      stationCode: stationCode,
      adminUserId: adminusrid,
    });
  }

  completeTask(userId = "", phoneNumber = "", workArea = "", stationCode = "", companyCode = "", taskCompleteData = []) {
    let adminusrid = ''
    if(localStorage.getItem('userId') !== ''){
      adminusrid = localStorage.getItem('userId')
    }
    const payload = {
      userId: userId,
      phoneNumber: phoneNumber,
      workArea: workArea,
      stationCode: stationCode,
      companyCode: companyCode,
      taskCompleteData: taskCompleteData,
      adminUserId: adminusrid,
    };
    return AxiosConfig.post(ApiEndPoints.COMPLETE_TASK, payload);
    
  }

  acknowledgeReceipt(
    userId = "",
    phoneNumber = "",
    stationCode = "",
    companyCode="",
    taskAckList = [],
    alertAckList = []
  ) {
    let adminusrid = ''
    if(localStorage.getItem('userId') !== ''){
      adminusrid = localStorage.getItem('userId')
    }
    const payload = {
      userId: userId,
      phoneNumber: phoneNumber,
      stationCode: stationCode,
      companyCode: companyCode,
      taskAckList: taskAckList,
      alertAckList: alertAckList,
      adminUserId: adminusrid,
    }; // Provide Payload here
    return AxiosConfig.post(ApiEndPoints.ACKNOWLEDGE_RECEIPT, payload);
  }

  getStationGates(stationCode, companyCode = "", department='') {
    let adminusrid = ''
    if(localStorage.getItem('userId') !== ''){
      adminusrid = localStorage.getItem('userId')
    }
    const params = {
      params: {
        station: stationCode,
        company: companyCode,
        department: department,
        adminUserId: adminusrid,
      }
    }
    return AxiosConfig.get(ApiEndPoints.STATION_GATES, params);
  }

  getWorkArea(stationCode, companyCode = "", department='') {
    let adminusrid = ''
    if(localStorage.getItem('userId') !== ''){
      adminusrid = localStorage.getItem('userId')
    }
    const params = {
      params: {
        station: stationCode,
        company: companyCode,
        department: department,
        adminUserId: adminusrid,
      }
    }
    return AxiosConfig.get(ApiEndPoints.ZONE_LIST_BY_STATION, params);
  }

  getStationList() {
    let adminusrid = ''
    if(localStorage.getItem('userId') !== ''){
      adminusrid = localStorage.getItem('userId')
    }
    const params = {
      params: {
        adminUserId: adminusrid,
      }
    }
    return AxiosConfig.get(ApiEndPoints.GET_STATION_LIST, params);
  }

  getTaskByTimeline(userId = "", employeeId = "", phoneNumber = "", stationCode = "", companyCode = "", shiftId = "") {
    let adminusrid = ''
    if(localStorage.getItem('userId') !== ''){
      adminusrid = localStorage.getItem('userId')
    }
    return AxiosConfig.post(ApiEndPoints.GET_TASK_BY_TIMELINE, {
      userId: userId,
      employeeId: employeeId,
      phoneNumber: phoneNumber,
      stationCode: stationCode,
      companyCode: companyCode,
      shiftId: shiftId,
      adminUserId: adminusrid,
    });
  }

  getTaskDataBySearch(userId = "", flightNumber = "", stationCode = "", companyCode = "", zone = [], gateNumber= [], groupTaskByDept="", department="", adhoc="", breakAndLunch='') {
    let adminusrid = ''
    if(localStorage.getItem('userId') !== ''){
      adminusrid = localStorage.getItem('userId')
    }
    const payload = {
      userId: userId,      
      flightNumber: flightNumber,
      zone: zone,
      stationCode: stationCode,
      companyCode: companyCode,
      gateNumbers: gateNumber,
      groupTaskByDept: groupTaskByDept,
      department: department,
      adhoc: adhoc,
      breakAndLunch: breakAndLunch,
      adminUserId: adminusrid,
    };
    return AxiosConfig.post(ApiEndPoints.GET_TASK_DATA_BY_SEARCH, payload);
  }
  
  initialDataDownload(userId = "", phoneNumber = "",stationCode = "", policyType="", policyAccepted="",deviceType="", deviceId="", company="", workArea='', fcmToken= '', adminUserId = ''){
    let sessionId = ''
    if(localStorage.getItem("uiSessionId") == null) {
      localStorage.setItem("uiSessionId", uuidv4())
    } 
    sessionId = localStorage.getItem("uiSessionId")
    const payload = {
      userId: userId,
      phoneNumber: phoneNumber,
      stationCode: stationCode,
      policyType: policyType,
      policyAccepted: policyAccepted,
      deviceType: deviceType,
      deviceId: deviceId,
      companyCode: company,
      fcmToken: fcmToken,
      workArea: workArea,
      adminUserId: adminUserId,
      uiSessionId:  sessionId
    };
    return AxiosConfig.post(ApiEndPoints.REGISTER, payload);
  }

  acceptPolicy(userId="", stationCode="", policyType="",policyAccepted=""){
    let adminusrid = ''
    if(localStorage.getItem('userId') !== ''){
      adminusrid = localStorage.getItem('userId')
    }
    const payload = {
      userId: userId,
      stationCode: stationCode,
      policyType: policyType,
      policyAccepted: policyAccepted,
      adminUserId: adminusrid,
    };
    return AxiosConfig.post(ApiEndPoints.ACCEPT_POLICY, payload);
  }

  submitFeedBack(userId = "", feedback = "",deviceType = ""){
    let adminusrid = ''
    if(localStorage.getItem('userId') !== ''){
      adminusrid = localStorage.getItem('userId')
    }
    const payload = {
      userId: userId,
      feedback: feedback,
      deviceType: deviceType,
      adminUserId: adminusrid,
    };
    return AxiosConfig.post(ApiEndPoints.FEEDBACK, payload);
  }

  setCode113Alert(employeeId = "", firstName = "", lastName = "", deviceId = "", deviceType = "", stationCode = "", appCode = "", phoneNumber = "", gateLocation = "", alertMessage = "", shiftId= "", PositionName="") {
    let adminusrid = ''
    if(localStorage.getItem('userId') !== ''){
      adminusrid = localStorage.getItem('userId')
    }
    const params = {
        employeeId: employeeId,
        firstName: firstName,
        lastName: lastName,
        deviceId: deviceId,
        deviceType: deviceType,
        appCode: appCode,
        stationCode: stationCode,
        phoneNumber: phoneNumber,
        gateLocation: gateLocation,
        alertMessage: alertMessage,
        shiftId: shiftId,
        PositionName: PositionName,
        adminUserId: adminusrid,
    };
      return AzureConfig.post(ApiEndPoints.CODE_113_ALERT, params);
  }

  getFAQ(deviceType = '', userId = '') {
    let adminusrid = ''
    if(localStorage.getItem('userId') !== ''){
      adminusrid = localStorage.getItem('userId')
    }
    const params = {
      params: {
        deviceType: deviceType,
        userId: userId,
        adminUserId: adminusrid,
      }
    }
    return AxiosConfig.get(ApiEndPoints.FAQ, params);
  }

  getNotifications(userId,stationCode,shiftId) {
    let adminusrid = ''
    if(localStorage.getItem('userId') !== ''){
      adminusrid = localStorage.getItem('userId')
    }
    const params = {
      params: {
        userId,
        stationCode,
        shiftId,
        adminUserId: adminusrid,
      }
    }
    return AxiosConfig.get(ApiEndPoints.BROADCAST_MESSAGES, params);
  }

  logOff(userId='', stationCode='', companyCode='', deviceType=''){
    let adminusrid = ''
    if(localStorage.getItem('userId') !== ''){
      adminusrid = localStorage.getItem('userId')
    }
    let sessionId = localStorage.getItem("uiSessionId")
    const payload = {
      userId:userId,
      stationCode:stationCode,
      companyCode:companyCode,
      deviceType: deviceType,
      uiSessionId: sessionId,
      adminUserId: adminusrid,
    };
    return AxiosConfig.post(ApiEndPoints.LOGOFF, payload)
  }

  getSearchDefaultInfo(userId) {
    let adminusrid = ''
    if(localStorage.getItem('userId') !== ''){
      adminusrid = localStorage.getItem('userId')
    }
    const params = {
      params: {
        adminUserId: adminusrid,
      }
    }
    return AxiosConfig.get(`${userId}/${ApiEndPoints.SEARCH_DEFAULT}`, params);
  }

  putSearchDefaultInfo(data) {
    let adminusrid = ''
    if(localStorage.getItem('userId') !== ''){
      adminusrid = localStorage.getItem('userId')
    }
    return AxiosConfig.put(`${data.userId}/${ApiEndPoints.SEARCH_DEFAULT}`, {
      "stationCode": data.stationCode,
      "department": data.department,
      "searchCriteria": data.searchCriteria,
      "searchValues": data.searchValues,
      "searchValue" : data.searchValue,
      "searchSubValues": data.searchSubValues,
      "searchSubValues2": data.searchSubValues2,
      "adminUserId": adminusrid,
    });
  }

  deleteSearchDefaultInfo(data) {
    return AxiosConfig.get(`${data.userId}/${ApiEndPoints.SEARCH_DEFAULT_DELETE}`);
  }

  resetSearchDefaultInfo(data) {
    let adminusrid = ''
    if(localStorage.getItem('userId') !== ''){
      adminusrid = localStorage.getItem('userId')
    }
    return AxiosConfig.put(`${data.userId}/${ApiEndPoints.SEARCH_DEFAULT}`, {
      "stationCode": "",
      "department": "",
      "searchCriteria": "",
      "searchValues": [],
      "searchValue" : "",
      "searchSubValues": [],
      "searchSubValues2": [],
      "adminUserId": adminusrid,
    });
  }

  getSubWorkArea(data) {
    /**
     * example request structure
     * "stationCode": "DFW",
      "department": "CS",
      "workAreas" : ["ABR Terminal Other", "Crews Zone 1 (A8 through A21)"]
     */
    return AxiosConfig.post(`${ApiEndPoints.SUBWORK_AREAS}`, data);
  }

  getShiftsList(data) {
    /**
     * example request structure
     * "stationCode": "DFW",
      "department": "CS",
      "workAreas" : ["ABR Terminal Other", "Crews Zone 1 (A8 through A21)"]
     */
    return AxiosConfig.post(`${ApiEndPoints.SHIFTS}`, data);
  }

  getTeamsList(data) {
    /**
     * example request structure
      "stationCode": "DFW",
      "department": "ALL",
      "subWorkAreas": ["A08", "A09"]
     */
    return AxiosConfig.post(`${ApiEndPoints.TEAMS}`, data);
  }

  /**
   * 
   * @param {
   * userId,
   * stationCode,
   * companyCode,
   * roles: [{
   * empId,
   * taskId,
   * role
   * }]
   * } data 
   * @returns 
   */
  setTaskRoles(data) {
    return AxiosConfig.put(`${ApiEndPoints.SET_TASK_ROLES}`, data)
  }

  getUserRoles(data) {
    let adminusrid = ''
    if(localStorage.getItem('userId') !== ''){
      adminusrid = localStorage.getItem('userId')
    }
    
    return AxiosConfig.get(`${ApiEndPoints.SET_TASK_ROLES}`, {
      params: {
        station: data.station,
        company: data.company,
        adminUserId: adminusrid,
      }
    })
  }

  getTaskHistorySearchApi(data) {
    return AxiosConfig.post(`${ApiEndPoints.GET_TASK_HISTORY}`, data);
    // return AxiosConfig.post(`https://rovr1-backend-api-dev.azurewebsites.net/dynamicManning/v1/getTaskHistory`, {...data});
  }

  adhocTaskCreateApi (userId, station, shiftId) {
    let adminusrid = ''
    if(localStorage.getItem('userId') !== ''){
      adminusrid = localStorage.getItem('userId')
    }
    return AxiosConfig.post(`${ApiEndPoints.CREATE_ADHOC_TASK_POST}`, {
      userId: userId,
      stationCode: station,
      shiftId: shiftId,
      adminUserId: adminusrid,
    });
  }

  getTipData(metricsType='', station='') {
    const params = {
      params: {
        metricsType: metricsType,
        stationCode: station
      }
    }

    return ScorecardConfig.get(`${ApiEndPoints.GET_TIP}`, params);
  }

  getScorecardData (adminEmployeeId='', employeeId='', stationCode='') {
    const params = {
      params: {
        adminEmployeeId,
        employeeId,
        stationCode
      }
    }
    return AxiosConfig.get(`${ApiEndPoints.SCORECARD_REPORT}`, params);

  }

  getScoreDashboard(employeeId = '', stationCode = ''){
    const params = {
      params: {
        employeeId,
        station: stationCode,
      }
    }    
    return ScorecardConfig.get(`${ApiEndPoints.GET_SCORE_DASHBOARD}`, params);
  }

  getEmployeeFlightDashboard(userId='',stationCode='',flightDate='') {
    const params = {
      params: {
        employeeId: userId,
        station: stationCode,
        flightDate,
      }
    }    
    return ScorecardConfig.get(`${ApiEndPoints.GET_FLIGHT_DETAILS}`, params);
  }
    
  setTourMode(userId = "", stationCode = "", companyCode="" ) {
    return AxiosConfig.post(`${ApiEndPoints.ACCEPT_ROVR_TOUR}`, {
      userId: userId,
      stationCode: stationCode,
      companyCode: companyCode,
      ackType: "RovrTour",
      deviceType: "Desktop",
      deviceModel: "Windows"
    });
  }
}

export const {
  getDataForAllUser,
  getCrewInfo,
  forceStartLavTask,
  acknowledgeReceipt,
  completeTask,
  getStationGates,
  getWorkArea,
  getStationList,
  getTaskByTimeline,
  getTaskDataBySearch,
  acceptPolicy,
  submitFeedBack,
  initialDataDownload,
  setAuthorizationToken,
  setCode113Alert,
  getFAQ,
  getNotifications,
  logOff,
  getSearchDefaultInfo,
  putSearchDefaultInfo,
  resetSearchDefaultInfo, 
  deleteSearchDefaultInfo,
  getSubWorkArea,
  getShiftsList,
  getTeamsList,
  setTaskRoles,
  getUserRoles,
  getTaskHistorySearchApi,
  adhocTaskCreateApi,
  getTipData,
  getScorecardData,
  getScoreDashboard,
  getEmployeeFlightDashboard,
  setTourMode,
} = new RovrAPI("instance");
