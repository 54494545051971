import {React, Component, Fragment} from 'react';
import ErrorComponent from './ErrorComponent';
import LoaderComponent from './LoaderComponent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as erroractions from '../error/ErrorAction';

class ErrorContainer extends Component {
    constructor(props) {
        super();
    }

    closeError = () => {
        this.props.action.removeErrorAction();
    }

    render() { 
        const { showError, apiError, loadingIndicator, errorCode } = this.props;
        return(
            <Fragment>
                <ErrorComponent showError={showError} closeError={this.closeError} apiError={apiError} errorCode={errorCode} />
                <LoaderComponent loadingIndicator={loadingIndicator} />
            </Fragment>
        );
    }
}

const mapDispatchToState = state => ({
    showError: state.errorReducer.showErrorMessage,
    apiError: state.errorReducer.apiError,
    errorCode: state.errorReducer.errorCode,
    loadingIndicator: state.errorReducer.loadingIndicator
});

const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({...erroractions}, dispatch),
});


export default connect(mapDispatchToState, mapDispatchToProps)(ErrorContainer);