import { DETAILS_DATA_ACTION, 
    DETAILS_DATA_SUCCESS, 
    FETCH_DETAILS_DATA_ERROR,
    UPDATE_TASK_ROLES,
    UPDATE_TASK_ROLES_SUCCESS,
    GET_USER_ROLES,
    GET_USER_ROLES_SUCCESS
 } from './detailsConstant';

export const fetchDetailsAction = (companyCode = "", stationCode = "", taskId = "", flightIds=[]) => ({
    type: DETAILS_DATA_ACTION,
    flightIds,
    companyCode,
    stationCode,
    taskId,
    flightIds
});

export const fetchDetailsDataSuccess = (responseData, taskId) => ({
    type: DETAILS_DATA_SUCCESS,
    payload: {
        ...responseData,
        taskId: taskId
    }
});
export const fetchDetailsDataError = (error) => ({
    type: FETCH_DETAILS_DATA_ERROR,
    payload:error
});

export const updateTaskRolesAction = (data) => {
    return {
        type: UPDATE_TASK_ROLES,
        data: data
    }
}

export const updateTaskRolesSuccess = (responseData, userId, companyCode, stationCode, roles) => {
    return {
        type: UPDATE_TASK_ROLES_SUCCESS,
        payload: {
            ...responseData,
            userId,
            companyCode,
            stationCode,
            roles
        }
    }
}

export const showPopupErrorUi = (data) => {
    return {
        type: "SHOW_POPUP_ERROR_UI",
        err: {...data}
    }
}