import { put, call, delay } from 'redux-saga/effects';
import { getFAQ } from '../../utils';
import * as actions from '../../modules/faqs/FaqsAction';
import * as erroractions from '../../modules/error/ErrorAction';
import { commonError } from '../../modules/error/ErrorCommonFunction';

export function* getFAQContent(action) {
    for(let retryCount = 0; retryCount < 3; retryCount++) {
        try {
            const response = yield call(getFAQ, action.deviceType, action.userId);
            response.data.errorResponseDTO.errorCode === 0 ?
                yield put(actions.fetchFaqsDataSuccess(response.data))
                :
                yield put(erroractions.showErrorAction(response.data.errorResponseDTO))
                break;
        }
        catch (error) {
            if(retryCount < 2 && (JSON.stringify(error.code) === '"ECONNABORTED"' || JSON.stringify(error.message) === '"Network Error"')) {
                yield delay(5000);
            } else {
                commonError(error);
                break;
            }
        }
    }
}