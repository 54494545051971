import React, { useState } from 'react';
import { Button, Modal, ModalTitle } from 'react-bootstrap';
import resources from '../../../utils/resources/resources.json';
import {Steps} from './Steps';
import {TourSteps} from './tourSteps';
import { useHistory } from "react-router-dom";

const StepModal = (props) => {
    const history = useHistory();
    const hideTour = () => {
        props.setShowExitTour(true)
    }

    const seeMore = () => {
        props.setShowLearnRovr(0)
    }

    return (
        <>
            <Modal dialogClassName="fullscreenmodal"
                show={props.showModal}
                onHide={hideTour}
                backdrop="static"
                keyboard={false}
                id="about"
            >
                <Modal.Header closeButton >
                    <Modal.Title className="aboutHead">Tasks</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   
                    <ModalTitle className="titleBody stepBody">
                        {Steps[TourSteps[props.currentTourStep].step].text()}
                        </ModalTitle>
                   
                </Modal.Body>
                <div className='stepTourButtons'>
                    <Button className="mr-2 buttonSearch submitColor buttonTour"
                    onClick={props.previousTourStep}>
                        Back
                    </Button>
                    {props.currentTourStep == 14 ?
                        <Button className="mr-2 buttonSearch resetColor buttonTour"
                        onClick={seeMore}>
                           See More
                        </Button>
                    :
                    <Button className="mr-2 buttonSearch resetColor buttonTour"
                    onClick={props.nextTourStep}>
                        Next
                    </Button>
                }
                    
                </div>
            </Modal>
        </>
    )
}

export default StepModal;