import * as actions from './code113Constants'

const initialState = {
    submitCode113: false,
}

const Code113Reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.FETCH_SET_CODE_113_SUCCESS: {
            return {
                ...state,
                submitCode113: action.data,
            }
        }
        default:
            return state;
    }
}

export default Code113Reducer;
