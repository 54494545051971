import React, { useState, useContext } from 'react';
import { Tab, Tabs } from "react-bootstrap";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LightHamburgerIcon from '../../../assets/images/Light_Web_Hamburger_Icon.svg';
import LightProfileIcon from '../../../assets/images/Light_Web_Profile_Icon.svg';
import LightCalendarIcon from '../../../assets/images/Light_Web_Calendar_Icon.svg';
import LightNotificationIcon from '../../../assets/images/Light_Notification_Btn.svg';
import RovvrIcon from '../../../assets/images/Rovr_Icon.png';
import NoTaskInfo_Icon from '../../../assets/images/Web_NoTask_Icon.svg';
import StepModal from './StepModal';
import { TourSteps } from './tourSteps';
import TourModeTask from './TourModeTask';
import ExitTourModal from './ExitTourModal';
import LearnRovrModal from './LearnRovrModal';
import * as taskactions from '../../../modules/task/taskAndShiftAction';

const TourModeScreen = (props) => {
    const [currentTourStep, setCurrentTourStep] = useState(0);
    const handActionButton = [3, 9, 11]
    const [isOpen, setIsOpen] = useState('open')
    const [showExitTour, setShowExitTour] = useState(false)
    const [showLearnRovr, setShowLearnRovr] = useState(null);

    const nextTourStep = () => {
        let current = currentTourStep;
        if (current == 2 && props.displayRovrTour) {
            props.actions.completeTourMode({
                userId: props.userId,
                stationCode: props.decodedToken.SM_STATION,
                companyCode: props.decodedToken.amrcompany
            })
        }   
        let next = current + 1;
        setCurrentTourStep(next)
     }

     const previousTourStep = () => {
        let current = currentTourStep;
        let prev = TourSteps[current].previousStep;
        setCurrentTourStep(prev)
    }

    return(

        <div className='tourContainer' >  
            { showExitTour?
            <ExitTourModal
                showExitTour={showExitTour}
                setShowExitTour={(state)=>setShowExitTour(state)}
                currentTourStep={currentTourStep}
            />: null 
            }    
            { TourSteps[currentTourStep].modal && !showExitTour && showLearnRovr == null ?
            <StepModal
            showModal={TourSteps[currentTourStep].modal}
            setCurrentTourStep={(state)=>setCurrentTourStep(state)}
            previousTourStep={()=>previousTourStep()}
            nextTourStep={()=>nextTourStep()}
            currentTourStep={currentTourStep}
            setShowExitTour={(state)=>setShowExitTour(state)}
            setShowLearnRovr={(state)=>setShowLearnRovr(state)}
            />: null 
            }
            {showLearnRovr !== null ?
            <LearnRovrModal
            show={showLearnRovr}
            setShowLearnRovr={(state)=>setShowLearnRovr(state)}
            
            />
            :null }
            
            <div className='tourTitle'>
                <p>
                Rovr+ Tour Mode
                </p>
                <span onClick={()=>setShowExitTour(true)}>X</span>
            </div>
            
            <div className="navContainer">
                <div className="container" style={{display: "flex", justifyContent: 'space-between'}}>
                    <div className="navbar-brand" >
                        <img style={{width: '50px', height: '50px'}} src={RovvrIcon}/>
                    </div>
                    <div className="navbarTourIcons" >
                        <img src={LightNotificationIcon} alt="BellIcon"  className="BellIcon"/>
                        <img src={LightCalendarIcon} className="calendarIconTour" alt="Calendar icon"/>
                        <div className="headerVerticalLine" />
                        <div className="headerProfileIcon"><img src={LightProfileIcon} alt="Profile icon"/></div>
                        <div className="headerProfileText">{props.decodedToken.SM_USER_FIRSTNAME} {props.decodedToken.SM_USER_LASTNAME}</div> 
                        <div className="headerVerticalLine" />
                        <div className="headerProfileText stationCode"> 
                        Station: {props.decodedToken.SM_STATION}
                    </div>
                        <img className="calendarIconTour" src={LightHamburgerIcon} alt="Hamburger Menu"/>
                    </div>
                </div>
            </div>

        {/* My task and search tabs */}
        <div
                className="container pb-9 tabContainer">
                     <Tabs 
                        fill id="controlled-tab-example" 
                        activeKey={"home"}
                    >
                        <Tab eventKey="home" title="My Task" >

                        </Tab>

                        <Tab eventKey="profile" title="Search" className="inactive-task-tab">
                        </Tab>

                        </Tabs>
                   
        { currentTourStep < 2 || currentTourStep == 14?
        (
            <p className="no-task-info" style={{marginLeft:"4.5rem"}}>
            <img
                src={NoTaskInfo_Icon}
                alt="No task information icon"
            />
            No task assigned at this moment. Please stand by for future assignment.
            </p> 
        )
        : null}
        {currentTourStep >= 2 && currentTourStep < 14 ?
        (
        <TourModeTask
            currentTourStep={currentTourStep}
            handActionButton={handActionButton}
            isOpen={isOpen}
            setIsOpen={(state)=>setIsOpen(state)}
            nextTourStep={()=>nextTourStep()}
            previousTourStep={()=>previousTourStep()}
        />
        )
            : null}
       </div>
</div>

    )
}

const mapDispatchToState = (state) => ({
    userId: state.AuthenticationReducer.userId,
    decodedToken: state.AuthenticationReducer.decodedToken,
    displayRovrTour: state.taskAndShiftReducer.displayRovrTour
  });
  
  const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ ...taskactions }, dispatch)
  });
  
  export default connect(
    mapDispatchToState,mapDispatchToProps)
    (TourModeScreen);
