export const TASK_AND_SHIFT_DATA_SUCCESS = 'TASK_AND_SHIFT_DATA_SUCCESS';
export const TASK_AND_SHIFT_DATA_ACTION = 'TASK_AND_SHIFT_DATA_ACTION';
export const SET_COMMENT_INDICATOR = 'SET_COMMENT_INDICATOR';
export const TASK_ADDED_ACTION = 'TASK_ADDED_ACTION';
export const TASK_DELETED_ACTION = 'TASK_DELETED_ACTION';
export const TASK_ADDED_SUCCESS = 'TASK_ADDED_SUCCESS';
export const TASK_DELETED_SUCCESS = 'TASK_DELETED_SUCCESS';
export const GSRT_UPDATE_COMPLETE = 'GSRT_UPDATE_COMPLETE';
export const GSRT_UPDATE_START = 'GSRT_UPDATE_START';
export const GSRT_UPDATE_ACK = 'GSRT_UPDATE_ACK';
export const LAV_FORCE_START_ACTION = 'LAV_FORCE_START_ACTION';
export const LAV_FORCE_START_SUCCESS = 'LAV_FORCE_START_SUCCESS';
export const LAV_FORCE_START_ERROR = 'LAV_FORCE_START_ERROR';
export const COMPLETE_TASK_ACTION = 'COMPLETE_TASK_ACTION';
export const COMPLETE_TASK_SUCCESS = 'COMPLETE_TASK_SUCCESS';
export const COMPLETE_TASK_SUCCESS2 = 'COMPLETE_TASK_SUCCESS2';
export const LAV_FORCE_START_ERROR_REMOVE = 'LAV_FORCE_START_ERROR_REMOVE';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ACTION = 'AUTH_ACTION';
export const USER_LOGOUT_ACTION = 'USER_LOGOUT_ACTION';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const PERFORM_LOGOUT = 'PERFORM_LOGOUT';
export const FETCH_TASK_TIMELINE_ACTION = 'FETCH_TASK_TIMELINE_ACTION';
export const FETCH_TASK_TIMELINE_SUCCESS = 'FETCH_TASK_TIMELINE_SUCCESS';
export const FETCH_TASK_TIMELINE_ERROR = 'FETCH_TASK_TIMELINE_ERROR';
export const  LOGIN_AUTH='LOGIN_AUTH';
export const TASK_AND_SHIFT_DATA_ERROR='TASK_AND_SHIFT_DATA_ERROR';
export const POST_TASK_ACT_DATA_ERROR='POST_TASK_ACT_DATA_ERROR';
export const COMPLETE_TASK_DATA_ERROR ='COMPLETE_TASK_DATA_ERROR';
export const DELETE_TASK_ACK_DATA_ERROR='DELETE_TASK_ACK_DATA_ERROR';
export const BROADCAST_ACK = 'BROADCAST_ACK';
export const BROADCAST_ACK_SUCCESS = 'BROADCAST_ACK_SUCCESS';
export const OCCURENCE_ACK_SUCCESS = 'OCCURENCE_ACK_SUCCESS';
export const CLEAR_BC_AND_TASK = 'CLEAR_BC_AND_TASK';
export const ADD_SPINNER = 'ADD_SPINNER';
export const REMOVE_SPINNER = 'REMOVE_SPINNER';
export const EMPTY_TASK_AND_SHIFT_DATA = 'EMPTY_TASK_AND_SHIFT_DATA';

export const CHANGE_TASK_BAR_TO_HOME = 'CHANGE_TASK_BAR_TO_HOME';
export const CHANGE_TASK_BAR_TO_PROFILE = 'CHANGE_TASK_BAR_TO_PROFILE';

export const ADHOC_TASK_CREATE = 'ADHOC_TASK_CREATE';
export const ADHOC_TASK_CREATE_SUCCESS = 'ADHOC_TASK_CREATE_SUCCESS';
export const ADHOC_TASK_CREATE_ERROR = 'ADHOC_TASK_CREATE_ERROR';
export const OPEN_TOUR_ON_LOGIN = 'OPEN_TOUR_ON_LOGIN';
export const CLOSE_TOUR_POPUP = 'CLOSE_TOUR_POPUP';
export const TOUR_MODE_COMPLETED = 'TOUR_MODE_COMPLETED';
