import {FAQS_DATA_ACTION, FAQS_DATA_SUCCESS}from "./FaqsConstant"

export const fetchFaqsDataAction = (deviceType="DESKTOP", userId="") => ({
    type: FAQS_DATA_ACTION,
    deviceType,
    userId
    
});

export const fetchFaqsDataSuccess = (response) => ({
    type: FAQS_DATA_SUCCESS,
    data: response
});