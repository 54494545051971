import { takeEvery } from 'redux-saga/effects'
import { TASK_AND_SHIFT_DATA_ACTION, TASK_ADDED_ACTION, TASK_DELETED_ACTION, LAV_FORCE_START_ACTION, COMPLETE_TASK_ACTION, FETCH_TASK_TIMELINE_ACTION, BROADCAST_ACK, ADHOC_TASK_CREATE, TOUR_MODE_COMPLETED} from '../../modules/task/taskAndShiftConstant';
import { PERFORM_LOGIN, PERFORM_LOGOUT, INITIAL_DATA_DOWNLOAD, LOGIN_AUTH, SET_AUTH_TOKEN} from '../../modules/authentication/AuthenticationConstants';
import { DETAILS_DATA_ACTION, UPDATE_TASK_ROLES, GET_USER_ROLES } from '../../modules/details/detailsConstant';
import { LOGIN_DATA_ACTION } from '../../modules/login/LoginConstant';
import { GET_STATION_LIST, GET_STATION_GATES, GET_WORK_AREA, GET_SEARCH_RESULT, CHANGE_DEFAULT_COMPLETED } from '../../modules/search/searchConstant';
import { fetchAccessToken, clearAuthResponse, initialData, loginAuth,setAuthToken  } from './authentication';
import { SUBMIT_FEEDBACK_FORM } from '../../modules/feed-back/FeedBackConstant';
import { fetchCrewDetails, updateTaskRolesFn, showPopupErrorUi } from './details';
import { fetchLogin } from './login';
import { fetchTaskandShift, taskAddAcknowledge, taskDelAcknowledge, forceStartLav, taskComplete, fetchTaskByTimeline, broadcastAck, adhocTaskCreatefn, tourModeCompleted } from './taskAndShift';
import { fetchStationList, fetchStationGates, fetchWorkArea, fetchSearchResult } from './search';
import {fetchSubWorkAreaList, fetchShiftsList, fetchTeamsList} from './shifts';
import { NOTIFICATION_MESSAGES } from '../../modules/notification/NotificationConstant';
import { FAQS_DATA_ACTION } from '../../modules/faqs/FaqsConstant';
import {getNotificationMessages} from './notification';
import {getFAQContent} from './faqs';
import { submitFeedbackForm } from './feedback';
import { submitCode113Alert } from '../../common/components/code113Modal/code113Saga';
import { getUserScore, getTipDataDownload, getScorecardReport, getFlightDetails } from './scorecard';
import { SET_CODE_113} from '../../common/components/code113Modal/code113Constants';
import { GET_SCORE, GET_TIP_DATA, GET_SCORECARD_REPORT, GET_FLIGHTS_DETAILS} from '../../modules/scorecard/scorecardConstants';
import { GET_SEARCH_DEFAULT, PUT_SEARCH_DEFAULT, DELETE_SEARCH_DEFAULT } from '../../modules/search/searchConstant'; 

import { FETCH_SUBWORKAREA_DATA, FETCH_SHIFT_DATA, FETCH_TEAMS_DATA } from '../../modules/shift/shiftConstant';

import { fetchSearchDefaultInfo, storeSearchDefaultInfo, clearSearchDefaultInfo, showDefaultErr 
} from './preference';

import {fetchTaskHistoryData} from './taskHistory';
import { TASK_HISTORY_SEARCH } from '../../modules/taskHistoryModule/taskHistoryConstant';

export function* watchTaskandShift() {
    yield takeEvery(TASK_AND_SHIFT_DATA_ACTION, fetchTaskandShift);
    yield takeEvery(TASK_ADDED_ACTION, taskAddAcknowledge);
    yield takeEvery(TASK_DELETED_ACTION, taskDelAcknowledge);
    yield takeEvery(LAV_FORCE_START_ACTION, forceStartLav);
    yield takeEvery(COMPLETE_TASK_ACTION, taskComplete);
    yield takeEvery(FETCH_TASK_TIMELINE_ACTION, fetchTaskByTimeline);
    yield takeEvery(BROADCAST_ACK, broadcastAck);
    yield takeEvery(ADHOC_TASK_CREATE, adhocTaskCreatefn);
}

export function* watchCrewDetails() {
    yield takeEvery(DETAILS_DATA_ACTION, fetchCrewDetails);
   //yield takeEvery(COMPLETE_TASK_ACTION, taskComplete);
}

export function* watchSetPopupFsCrew() {
    yield takeEvery(UPDATE_TASK_ROLES, updateTaskRolesFn);
    yield takeEvery("SHOW_POPUP_ERROR_UI" , showPopupErrorUi)
}

export function* watchLogin() {
    yield takeEvery(LOGIN_DATA_ACTION, fetchLogin);
}

export function* watchSearch() {
    yield takeEvery(GET_STATION_LIST, fetchStationList);
    yield takeEvery(GET_STATION_GATES, fetchStationGates);
    yield takeEvery(GET_WORK_AREA, fetchWorkArea);
    yield takeEvery(GET_SEARCH_RESULT, fetchSearchResult);
}

export function* watchAuthentication() {
    yield takeEvery(PERFORM_LOGIN, fetchAccessToken);
    yield takeEvery(PERFORM_LOGOUT, clearAuthResponse);
    yield takeEvery(INITIAL_DATA_DOWNLOAD, initialData);
    yield takeEvery(LOGIN_AUTH, loginAuth);
    yield takeEvery(SET_AUTH_TOKEN, setAuthToken);
}

export function* watchNotification () {
    yield takeEvery(NOTIFICATION_MESSAGES, getNotificationMessages);
}

export function* watchFaqs(){
    yield takeEvery(FAQS_DATA_ACTION, getFAQContent);
}

export function* watchFeedback(){
    yield takeEvery(SUBMIT_FEEDBACK_FORM, submitFeedbackForm);
}

export function* watchCode113() {
    yield takeEvery(SET_CODE_113, submitCode113Alert);
}

export function* watchGetTip() {
    yield takeEvery(GET_SCORE, getUserScore);
    yield takeEvery(GET_TIP_DATA, getTipDataDownload);
    yield takeEvery(GET_SCORECARD_REPORT, getScorecardReport);
    yield takeEvery(GET_FLIGHTS_DETAILS, getFlightDetails);
}

export function* watchSearchDefault() {
    yield takeEvery(GET_SEARCH_DEFAULT, fetchSearchDefaultInfo);
    yield takeEvery(PUT_SEARCH_DEFAULT, storeSearchDefaultInfo);
    yield takeEvery(DELETE_SEARCH_DEFAULT, clearSearchDefaultInfo);
    yield takeEvery("SHOW_DEFAULT_ERROR_UI", showDefaultErr);
}

export function* watchSearchShifts() {
    yield takeEvery(FETCH_SUBWORKAREA_DATA, fetchSubWorkAreaList);
    yield takeEvery(FETCH_SHIFT_DATA, fetchShiftsList);
    yield takeEvery(FETCH_TEAMS_DATA, fetchTeamsList);
}

export function* watchTaskHistory() {
    yield takeEvery(TASK_HISTORY_SEARCH, fetchTaskHistoryData);
}

export function* watchTourMode() {
    yield takeEvery(TOUR_MODE_COMPLETED, tourModeCompleted);
}

