import { LOGIN_DATA_ACTION, RESET_DATA_ACTION, LOGIN_DATA_ERROR, LOGINAS_SUCCESS, LOGINAS_FAILURE,RESET_AS_HOME } from './LoginConstant'

export const setLoginAction = (userId = '', phoneNumber = '', stationCode = '', deviceType= 'Desktop', companyCode = '', adminUserId = '') => ({
    type: LOGIN_DATA_ACTION,
    userId,
    phoneNumber,
    stationCode,
    deviceType,
    companyCode,
    adminUserId
});

export const resetLogin=()=>({
    type:RESET_DATA_ACTION
});

export const resetAsHome=()=>({
    type:RESET_AS_HOME
});

export const setLoginError=()=>({
    type:LOGIN_DATA_ERROR
})

export const loginAsSuccess = (responseData) => ({
    type: LOGINAS_SUCCESS,
    payload: responseData
});

export const loginAsFailure = (responseData) => ({
    type: LOGINAS_FAILURE,
    payload: responseData
});