import { isEmpty } from "lodash";

export function getAuthUser(loginAsData, decodedToken){
    const smuser = {};
    const decodedTokenLS = JSON.parse(localStorage.getItem('tokenData'));
    smuser.sm_user = isEmpty(loginAsData.userId) ? decodedToken.SM_USER : loginAsData.userId;
    smuser.sm_station = isEmpty(loginAsData.userId) ? decodedTokenLS.SM_STATION : loginAsData.stationCode;
    smuser.sm_company = isEmpty(loginAsData.userId) ? decodedToken.amrcompany : loginAsData.companyCode;

    return smuser;
}

export function trimUserId(userId){
   const first2Char =  userId.substr(0,2);
   if(first2Char === "00") {
         return userId.substr(2);      
   } else {
         return userId;
   }   
}