import { put, call, delay} from 'redux-saga/effects';
import * as actions from '../../modules/notification/NotificationAction';
import { getNotifications } from '../../utils';
import * as erroractions from '../../modules/error/ErrorAction';
import { commonError } from '../../modules/error/ErrorCommonFunction';
//import testData from './testDataNotificationCode113.json'

export function* getNotificationMessages (action) {
    for(let retryCount = 0; retryCount < 3; retryCount++) {
        try {
            const response = yield call(
                getNotifications,
                action.userId,
                action.stationCode,
                action.shiftId
            );
    
            response.data.errorResponseDTO.errorCode === 0 ?
                yield put(actions.notificationMessagesSuccess(response.data))
                :
                yield put(erroractions.showErrorAction(response.data.errorResponseDTO))
                break;
        }
        catch(error) {
            if(retryCount < 2 && (JSON.stringify(error.code) === '"ECONNABORTED"' || JSON.stringify(error.message) === '"Network Error"')) {
                yield delay(5000);
            } else {
                commonError(error);
                break;
            }
        }
    }
}