import {FAQS_DATA_SUCCESS} from './FaqsConstant';

const initialState = {
    faqContent: {}
};

const FaqsReducer = (state = initialState, action) => {
    switch(action.type) {
        case FAQS_DATA_SUCCESS: {
            return {
                ...state,
                faqContent: action.data
            }
        }
        default: return state;
    }
}

export default FaqsReducer;