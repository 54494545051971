import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import  Spinner from '../../common/components/spinner/Spinner';
import * as actions from "../authentication/AuthenticationAction";
import {trimUserId} from './AuthenticationHandler';
import * as scorecardActions from '../../modules/scorecard/scorecardAction';
import { isEmpty } from 'lodash';

class AuthenticationComponent extends Component {  
  constructor(props) {
    super(props);
    this.state = { redirect: null, decodedToken: {} };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.accessToken !== prevState.accessToken) {
      if (nextProps.rovrUser && nextProps.decodedToken.amrcompany == 'AA') {
        return  { redirect: "/task" };
      } else {
        return { redirect: "/scorecard" };
      }
    }
    return null;
  }

  componentDidMount() {
    const { action } = this.props;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");
    const tokenData = localStorage.getItem("tokenData");
    const token = localStorage.getItem("token");
    // const userData = this.props.decodedToken;

    if (!code && !token ) {
      action.listenLogin();
      return;
    }
    
    if (code && !token) {
      action.authenticateAction(code, tokenData, token);
      return;
    }

    if (token) {
      const tokenData = JSON.parse(localStorage.getItem("tokenData"));
      window.addEventListener("storage", (e) => {
        const validToken = localStorage.getItem("token");
        if (validToken === null) {
          action.listenLogout(trimUserId(tokenData.SM_USER), tokenData.SM_STATION, tokenData.amrcompany);
        }
      });
      var accessToken = token;
      action.setAuthToken(accessToken,tokenData.SM_USER, tokenData.SM_STATION, tokenData.amrcompany);
      action.authenticateSuccess(tokenData);
      if (tokenData.amrcompany == 'AA' || tokenData.amrcompany == '') {
        if (!isEmpty(this.props.loginAsData)) {
          action.getUserScore({
            employeeId: this.props.loginAsData.userId,
            stationCode: this.props.loginAsData.stationCode   
          })
        } else {
          action.getUserScore({
            employeeId: tokenData.SM_USER,
            stationCode: tokenData.SM_STATION   
          })
        }
      }
      action.initialDataDownload(trimUserId(tokenData.SM_USER), "D_224097", tokenData.SM_STATION, " ", " ", "Desktop", " ", tokenData.amrcompany, " ", " ", " ");
      this.setState({ redirect: "/task" });
    }
  }

  render() {
    if (this.state.redirect) {
      // this.props.navigate('/task')
      // return null
      return <Redirect to={this.state.redirect} />;
    }
    return <Spinner />;
  }
}

const mapDispatchToState = (state) => ({
  decodedToken: state.AuthenticationReducer.decodedToken,
  accessToken: state.AuthenticationReducer.accessToken,
  rovrUser: state.AuthenticationReducer.registerData.rovrUser,
  loginAsData: state.loginReducer.loginAsData,
  companyCode: state.AuthenticationReducer.companyCode,
});

const mapDispatchToProps = (dispatch) => ({
  action: bindActionCreators({ ...actions,...scorecardActions }, dispatch),
});

export default connect(
  mapDispatchToState,
  mapDispatchToProps
)(AuthenticationComponent);