import { listenLogout } from '../authentication/AuthenticationAction';
import * as erroractions from './ErrorAction';
import {store} from '../../app/App';

export const commonError = (error) => {
    if(error.response) {
        if(error.response.status === 401) {
            store.dispatch(listenLogout(
                store.getState().AuthenticationReducer.decodedToken.SM_USER,
                store.getState().AuthenticationReducer.decodedToken.SM_STATION,
                store.getState().AuthenticationReducer.decodedToken.amrcompany
            ));
        }
        store.dispatch(erroractions.showErrorAction({
            errorCode: error.response.status,
            errorMessage: 'Error Message'
        }));
    } else {
        if(JSON.stringify(error.code) === '"ECONNABORTED"'){
            store.dispatch(erroractions.showErrorAction({
                errorCode: 'timeout',
                errorMessage: 'Error Message'
            }));
        } else {
            store.dispatch(erroractions.showErrorAction({
                errorCode: 'cancelled',
                errorMessage: 'Error Message'
            }));
        }
    }
}