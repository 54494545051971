import {SUBMIT_FEEDBACK_FORM_SUCCESS} from './FeedBackConstant';

const initialState = {
    feedBackResponse: {}
};

const FeedBackReducer = (state = initialState, action) => {
    switch(action.type) {
        case SUBMIT_FEEDBACK_FORM_SUCCESS: {
            return {
                ...state,
                feedBackResponse: action.data
            }
        }
        default: return state;
    }
}

export default FeedBackReducer;