import React from 'react';
import CustomerIconblue from '../../../assets/images/Web_Customer_Icon_Blue.svg';
import CustomerIconorange from '../../../assets/images/Web_Customer_Icon_Orange.svg';
import CustomerIcongray from '../../../assets/images/Web_Customer_Icon_Gray.svg';
import CustomerIcongreen from '../../../assets/images/Web_Customer_Icon_Green.svg';
import CustomerIcontlink from '../../../assets/images/Web_Customer_Icon_Tlink.svg';
import LightHamburgerIcon from '../../../assets/images/Light_Web_Hamburger_Icon.svg';
import LightCalendarIcon from '../../../assets/images/Light_Web_Calendar_Icon.svg';
import RovrIcon from '../../../assets/images/Rovr_Icon.png';
import SearchTab from '../../../assets/images/searchTab.png';
import TaskDetails from '../../../assets/images/task_details.png';
import LoadingOrange from '../../../assets/images/LoadingOrange.svg';
import LoadingSpinner from '../../../assets/images/LoadingSpinner.svg';
import LoadingGreen from "../../../assets/images/LoadingGreen.svg";
import LoadingGrey from "../../../assets/images/LoadingGrey.svg";
import FlightDiversion from "../../../assets/images/flight_diversion.svg";
import FlightArrival from "../../../assets/images/Web_FlightArrival_Icon.svg";
import FlightDeparture from "../../../assets/images/Web_FlightDeparture_Icon.svg";
import UnreadMessage from "../../../assets/images/my_task_unread.svg";
import ReadMessage from "../../../assets/images/my_task_read.svg";
import SplitTurn from "../../../assets/images/split_role.svg";
import UnsplitTurn from "../../../assets/images/merge_role.svg";
import { Player, Controls } from '@lottiefiles/react-lottie-player';

export const learnRovrData = [
    { title: 'Menu',
    text: 'Tap on the Menu to change your settings, share the Rovr app, access Help and Feedback or Log out.',
    img: <img src={LightHamburgerIcon}
        style={{ marginLeft: 10 }} />
    },
    {
        title: 'Shift Overview',
        text: 'See your shifts for today and tomorrow.  For the current shift, you can also view the timeline of all tasks and activity for that shift.',
        img: <img src={LightCalendarIcon} 
            style={{ marginLeft: 10 }} />,
    },
    {
        title: 'Search Page',
        text: 'Search for tasks by flight, gate, employee id,  workarea and more.  Also search for all employees with current shifts for a specified workarea, subworkarea or team.',
        img:

        <img src={SearchTab}
                style={{ marginLeft: 10, width: 120, height: 50 }} />
        ,
    },
    {
        title: 'Task Details',
        text: 
        <div style={{display: 'flex', flexDirection:'column'}}>
            <img src={TaskDetails}
            style={{ alignSelf: 'center', justifySelf: 'center', width: 260, height: 240, marginBottom: '20px' }} />
            <p>
                Task details with Crew information and a comment section will appear by tapping on the task item.
            </p>
        </div>
    },
    {
       title: 'Symbols',
        text: 
        <div>
           <div style={{display: 'flex', marginLeft: '10px'}}>
                <img style={{paddingBottom: '15px',
    margin: '5px', width: '40px'}} alt={'split turn icon'} src={SplitTurn} />
                <p style={{marginTop: '5px', marginLeft: '20px', alignSelf: 'center'}}>Split roles in turns</p>
            </div>
            <div style={{display: 'flex', marginLeft: '10px'}}>
                <img style={{paddingBottom: '15px',
    margin: '5px', width: '40px'}} alt={'split turn icon'} src={UnsplitTurn} />
                <p style={{marginTop: '5px', marginLeft: '20px', alignSelf: 'center'}}>Unsplit roles in turns</p>
            </div>
            <div style={{display: 'flex', marginLeft: '10px'}}>
                <img style={{paddingBottom: '15px',
    margin: '5px', width: '40px'}}src={FlightDiversion} />
                <p style={{marginTop: '5px', marginLeft: '20px', alignSelf: 'center'}}>Flight Diversion</p>
            </div>
            <div style={{display: 'flex', marginLeft: '10px'}}>
                <img style={{paddingBottom: '15px',
    margin: '5px'}}src={FlightDeparture} />
                <p style={{marginTop: '5px', marginLeft: '20px'}}>Flight Departure</p>
            </div>
            <div style={{display: 'flex', marginLeft: '10px'}}>
                <img style={{paddingBottom: '15px',
    margin: '5px'}}src={FlightArrival} />
                <p style={{marginTop: '5px', marginLeft: '20px'}}>Flight Arrival</p>
            </div>
            <div style={{display: 'flex', marginLeft: '10px'}}>
                <div className="flight-intl-flag" style={{marginLeft: '10px'}}>INTL</div>
                <p style={{marginTop: '5px', marginLeft: '35px'}}>International Flight</p>
            </div>
            <div style={{display: 'flex', marginLeft: '10px'}}>
                <div className="flight-preclr-flag" style={{marginLeft: '10px'}}>PRECLR</div>
                <p style={{marginTop: '5px', marginLeft: '20px'}}>Precleared Flight</p>
            </div>
            <div style={{display: 'flex', marginLeft: '10px'}}>
                <img style={{paddingBottom: '15px', margin: '5px'}} src={UnreadMessage} />
                <p style={{marginTop: '5px', marginLeft: '20px', alignSelf: 'center'}}>Unread Message</p>
            </div>
            <div style={{display: 'flex', marginLeft: '10px'}}>
                <img style={{paddingBottom: '15px', margin: '5px'}} src={ReadMessage} />
                <p style={{marginTop: '5px', marginLeft: '20px', alignSelf: 'center'}}>Read Message</p>
            </div>
            <div style={{display: 'flex', maxHeight: '70px'}}>
                <Player src={require('../../../assets/animations/loader.json')} background="transparent"  speed="1"  style={{marginLeft:15, marginTop:8, width: 40, height: 40, position: 'absolute'}} autoplay loop/>
                <img style={{paddingBottom: '15px',
    margin: '5px'}}src={LoadingOrange} />
                <p style={{marginTop: '5px', marginLeft: '10px', alignSelf: 'center'}}>Confirming</p>
            </div>
            <div style={{display: 'flex', maxHeight: '70px'}}>
                <Player src={require('../../../assets/animations/loader.json')} background="transparent"  speed="1"  style={{marginLeft:15, marginTop:8, width: 40, height: 40, position: 'absolute'}} autoplay loop/>
                <img style={{paddingBottom: '15px',
    margin: '5px'}}src={LoadingGreen} />
                <p style={{marginTop: '5px', marginLeft: '10px', alignSelf: 'center'}}>Starting</p>
            </div>
            <div style={{display: 'flex', maxHeight: '70px'}}>
                <Player src={require('../../../assets/animations/loader.json')} background="transparent"  speed="1"  style={{marginLeft:15, marginTop:8, width: 40, height: 40, position: 'absolute'}} autoplay loop/>
                <img style={{paddingBottom: '15px',
    margin: '5px'}}src={LoadingGrey} />
                <p style={{marginTop: '5px', marginLeft: '10px', alignSelf: 'center'}}>Completing</p>

            </div>
        </div>
    },
    {
        title: 'Color Code',
         text: 
         <div>
             <div style={{display: 'flex'}}>
                 <img style={{paddingBottom: '15px',
     margin: '5px'}}src={CustomerIconblue} />
                 <p style={{marginTop: '5px', marginLeft: '10px'}}>Assigned</p>
             </div>
             <div style={{display: 'flex'}}>
                 <img style={{paddingBottom: '15px',
     margin: '5px'}}src={CustomerIconorange} />
                 <p style={{marginTop: '5px', marginLeft: '10px'}}>Confirmed</p>
             </div>
             <div style={{display: 'flex'}}>
                 <img style={{paddingBottom: '15px',
     margin: '5px'}}src={CustomerIcongreen} />
                 <p style={{marginTop: '5px', marginLeft: '10px'}}>Started</p>
             </div>
             <div style={{display: 'flex'}}>
                 <img style={{paddingBottom: '15px',
     margin: '5px'}}src={CustomerIcontlink} />
                 <p style={{marginTop: '5px', marginLeft: '10px'}}>TLink Route Generated</p>
             </div>
             <div style={{display: 'flex'}}>
                 <img style={{paddingBottom: '15px',
     margin: '5px'}}src={CustomerIcongray} />
                 <p style={{marginTop: '5px', marginLeft: '10px'}}>Finished</p>
             </div>
         </div>
     },
    {
        title: 'Code 113',
        text: 'By rapidly tapping the ROVR icon in the upper left of your screen 3 times, you can invoke the Code 113 alert.  You will have the opportunity to confirm and send the alert or cancel.',
        img: <img src={RovrIcon}
            style={{ height: '37px', marginLeft: 10 }} />
    }
]
