import {call, put, delay } from 'redux-saga/effects';
import * as actions from '../../modules/search/searchAction';
import { getStationList, getStationGates, getWorkArea, getTaskDataBySearch } from '../../utils';
import * as erroractions from '../../modules/error/ErrorAction';
import { commonError } from '../../modules/error/ErrorCommonFunction';

export function* fetchStationList () {
    for(let retryCount = 0; retryCount < 3; retryCount++) {
        try {
            const response = yield call(getStationList);
            response.data.errorResponseDTO.errorCode === 0 ?
                yield put(actions.getStationListSuccess(response.data))
                :
                yield put(erroractions.showErrorAction(response.data.errorResponseDTO))
                break;
        }
        catch(error) {
            if(retryCount < 2 && (JSON.stringify(error.code) === '"ECONNABORTED"' || JSON.stringify(error.message) === '"Network Error"')) {
                yield delay(5000);
            } else {
                commonError(error);
                break;
            }
        }
    }
}

export function* fetchStationGates (action) {
    for(let retryCount = 0; retryCount < 3; retryCount++) {
        try {
            const response = yield call(getStationGates, action.stationCode, action.companyCode, action.department);
            response.data.errorResponseDTO.errorCode === 0 ?
                yield put(actions.getStationGatesSuccess(response.data))
                :
                yield put(erroractions.showErrorAction(response.data.errorResponseDTO))
                break
        }
        catch(error){
            if(retryCount < 2 && (JSON.stringify(error.code) === '"ECONNABORTED"' || JSON.stringify(error.message) === '"Network Error"')) {
                yield delay(5000);
            } else {
                commonError(error);
                break;
            }
        }
    }
}

export function* fetchWorkArea (action) {
    for(let retryCount = 0; retryCount < 3; retryCount++) {
        try {
            const response = yield call(getWorkArea, action.stationCode, action.companyCode, action.department);
            response.data.errorResponseDTO.errorCode === 0 ?
                yield put(actions.getWorkAreaSuccess(response.data))
                :
                yield put(erroractions.showErrorAction(response.data.errorResponseDTO))
                break;
        }
        catch(error) {
            if(retryCount < 2 && (JSON.stringify(error.code) === '"ECONNABORTED"' || JSON.stringify(error.message) === '"Network Error"')) {
                yield delay(5000);
            } else {
                commonError(error);
                break;
            }
        }
    }
}

export function* fetchSearchResult (action) {
    for(let retryCount = 0; retryCount < 3; retryCount++) {
        try {
            const response = yield call(
                getTaskDataBySearch,
                action.userId,
                action.flightNumber,
                action.stationCode,
                action.companyCode,
                action.zone,
                action.gateNumber,
                action.groupTaskByDept,
                action.department,
                action.adhoc,
                action.breakAndLunch
            );
    
            response.data.errorResponseDTO.errorCode === 0 ?
                yield put(actions.getSearchResultSuccess(response.data))
                :
                yield put(erroractions.showErrorAction(response.data.errorResponseDTO))
                break;
        }
        catch(error) {
            if(retryCount < 2 && (JSON.stringify(error.code) === '"ECONNABORTED"' || JSON.stringify(error.message) === '"Network Error"')) {
                yield delay(5000);
            } else {
                commonError(error);
                break;
            }
        }
    }
} 
