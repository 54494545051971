import {NOTIFICATION_MESSAGES_SUCCESS} from './NotificationConstant';

const initialState = {
    notificationMessages: {}
};

const NotificationReducer = (state = initialState, action) => {
    switch(action.type) {
        case NOTIFICATION_MESSAGES_SUCCESS: {
            return {
                ...state,
                notificationMessages: action.data
            }
        }
        default: return state;
    }
}

export default NotificationReducer;