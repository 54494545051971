export const PERFORM_LOGIN= 'PERFORM_LOGIN';
export const PERFORM_LOGOUT= 'PERFORM_LOGOUT';
export const REMOVED_TOKENS_SUCCESS= 'REMOVED_TOKENS_SUCCESS';
export const AUTH_SUCCESS='AUTH_SUCCESS';
export const SET_AUTH_TOKEN="SET_AUTH_TOKEN";
export const INITIAL_DATA_DOWNLOAD="INITIAL_DATA_DOWNLOAD";
export const LOGIN_AUTH="LOGIN_AUTH";
export const INITIAL_DATA_ERROR='INITIAL_DATA_ERROR';
export const LOGIN_AUTH_ERROR='LOGIN_AUT_ERROR';
export const SET_AUTH_TOKEN_ERROR='SET_AUTH_TOKEN_ERROR';
export const LOGOUT_LOADER='LOGOUT_LOADER';
export const INITIAL_DATA_DOWNLOAD_SUCCESS="INITIAL_DATA_DOWNLOAD_SUCCESS";

