import  resources from './resources/resources.json';
const selectBaseURL = (station, companyCode) => {
  const stationList1WithAPI1 = [
    "DFW",
    "LAX",
    "MIA",
    "ORD",
    "CLT",
    "DCA",
    "PHL",
    "PHX",
    "LGA",
    "JFK"
  ];
  switch (true) {
    case stationList1WithAPI1.indexOf(station) !== -1 && companyCode === "AE": {
      return resources.BASE_URL_1;
      
    }
    default:
      return resources.BASE_URL;
  }
};
export default selectBaseURL;
