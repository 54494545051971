import { put, call, all, delay } from "redux-saga/effects";
import {
  getDataForAllUser,
  acknowledgeReceipt,
  forceStartLavTask,
  completeTask,
  getTaskByTimeline,
  adhocTaskCreateApi,
  setTourMode
} from "../../utils";
import * as actions from "../../modules/task/taskAndShiftAction";
// import { listenLogout } from '../../modules/authentication/AuthenticationAction';
import * as erroractions from "../../modules/error/ErrorAction";
import { commonError } from "../../modules/error/ErrorCommonFunction";

export function* fetchTaskandShift(action) {
  for (let retryCount = 0; retryCount < 3; retryCount++) {
    try {
      const response = yield call(
        getDataForAllUser,
        action.userId,
        action.stationCode,
        action.phoneNumber,
        action.companyCode
      );
      response.data.errorResponseDTO.errorCode === 0
      ? yield put(actions.fetchTaskandShiftDataSuccess(response.data))
      : yield all([
          yield put(actions.fetchTaskandShiftDataSuccess(response.data)),
          yield put(
            erroractions.showErrorAction(response.data.errorResponseDTO)
          ),
        ]);
      break;
    } catch (error) {
      // if(retryCount < 2 && (JSON.stringify(error.code) === '"ECONNABORTED"' || JSON.stringify(error.code) === undefined)) {
      if (
        retryCount < 2 &&
        (JSON.stringify(error.code) === '"ECONNABORTED"' ||
          JSON.stringify(error.message) === '"Network Error"')
      ) {
        yield delay(5000);
      } else {
        commonError(error);
        break;
      }
    }
  }
}

export function* taskAddAcknowledge(action) {
  for (let retryCount = 0; retryCount < 3; retryCount++) {
    try {
      yield put(erroractions.showLoaderAction());
      const response = yield call(
        acknowledgeReceipt,
        action.userId,
        action.phoneNumber,
        action.stationCode,
        action.companyCode,
        action.taskAckList,
        action.alertAckList
      );      
      if (response.data.errorResponseDTO.errorCode === 0) {
        yield put(actions.gsrtUpdatedStatusAck({state: response.data.gsrtUpdatePending, taskId: response.data.taskId}))
        yield put(erroractions.removeLoaderAction());
        yield put(
          actions.postTaskAckDataSucccess(
            response.data,
            action.taskAckList[0].taskRID,
            action.taskAckList[0].taskInstanceId
          ),
        );
        break;
      } else if (response.data.errorResponseDTO.errorCode === 1100) {
        yield delay(5000);
        const response = yield call(
          acknowledgeReceipt,
          action.userId,
          action.phoneNumber,
          action.stationCode,
          action.companyCode,
          action.taskAckList,
          action.alertAckList
        );
        if (response.data.errorResponseDTO.errorCode === 0) {
          yield put(
            actions.postTaskAckDataSucccess(
              response.data,
              action.taskAckList[0].taskRID,
              action.taskAckList[0].taskInstanceId
            )
          );
          break;
        } else {
          yield put(
            erroractions.showErrorAction(response.data.errorResponseDTO)
          );
          break;
        }
      } else {
        yield put(erroractions.showErrorAction(response.data.errorResponseDTO));
        break;
      }
    } catch (error) {
      if (
        retryCount < 2 &&
        (JSON.stringify(error.code) === '"ECONNABORTED"' ||
          JSON.stringify(error.message) === '"Network Error"')
      ) {
        yield delay(5000);
      } else {
        commonError(error);
        break;
      }
    }
  }
}

export function* taskDelAcknowledge(action) {
  for (let retryCount = 0; retryCount < 3; retryCount++) {
    try {
      yield put(erroractions.showLoaderAction());
      const response = yield call(
        acknowledgeReceipt,
        action.userId,
        action.phoneNumber,
        action.stationCode,
        action.companyCode,
        action.taskAckList,
        action.alertAckList
      );

      yield put(erroractions.removeLoaderAction());
      if (response.data.errorResponseDTO.errorCode === 0) {
        yield put(
          actions.deleteTaskAckDataSucccess(
            response.data,
            action.taskAckList[0].taskRID,
            action.taskAckList[0].taskInstanceId
          )
        );
        break;
      } else if (response.data.errorResponseDTO.errorCode === 1100) {
        yield delay(5000);
        const response = yield call(
          acknowledgeReceipt,
          action.userId,
          action.phoneNumber,
          action.stationCode,
          action.companyCode,
          action.taskAckList,
          action.alertAckList
        );
        if (response.data.errorResponseDTO.errorCode === 0) {
          yield put(
            actions.deleteTaskAckDataSucccess(
              response.data,
              action.taskAckList[0].taskRID,
              action.taskAckList[0].taskInstanceId
            )
          );
          break;
        } else {
          yield put(
            erroractions.showErrorAction(response.data.errorResponseDTO)
          );
          break;
        }
      } else {
        yield put(erroractions.showErrorAction(response.data.errorResponseDTO));
        break;
      }
    } catch (error) {
      if (
        retryCount < 2 &&
        (JSON.stringify(error.code) === '"ECONNABORTED"' ||
          JSON.stringify(error.message) === '"Network Error"')
      ) {
        yield delay(5000);
      } else {
        commonError(error);
        break;
      }
    }
  }
}

export function* forceStartLav(action) {
  for (let retryCount = 0; retryCount < 3; retryCount++) {
    try {
      const response = yield call(
        forceStartLavTask,
        action.userId,
        action.taskInstanceId,
        action.stationCode
      );
      if (response.data.errorResponseDTO.errorCode === 0) {
        yield put(actions.gsrtUpdatedStatusStart({state: response.data.gsrtUpdatePending, taskId: response.data.taskId}))
        yield put(
          actions.forceStartLavSuccess(response.data, action.taskInstanceId)
        );
        break;
      } else if (response.data.errorResponseDTO.errorCode === 1100) {
        yield delay(5000);
        const response = yield call(
          forceStartLavTask,
          action.userId,
          action.taskInstanceId,
          action.stationCode
        );
        if (response.data.errorResponseDTO.errorCode === 0) {
          yield put(
            actions.forceStartLavSuccess(response.data, action.taskInstanceId)
          );
          break;
        } else {
          yield put(
            erroractions.showErrorAction(response.data.errorResponseDTO)
          );
          break;
        }
      } else {
        yield put(erroractions.showErrorAction(response.data.errorResponseDTO));
        break;
      }
    } catch (error) {
      if (
        retryCount < 2 &&
        (JSON.stringify(error.code) === '"ECONNABORTED"' ||
          JSON.stringify(error.message) === '"Network Error"')
      ) {
        yield delay(5000);
      } else {
        commonError(error);
        break;
      }
    }
  }
}

export function* taskComplete(action) {
  for (let retryCount = 0; retryCount < 3; retryCount++) {
    try {
      const response = yield call(
        completeTask,
        action.userId,
        action.phoneNumber,
        action.workArea,
        action.stationCode,
        action.companyCode,
        action.taskCompleteData
      );
      if (response.data.errorResponseDTO.errorCode === 0) {
          yield put(actions.gsrtUpdatedStatusComplete({state: response.data.gsrtUpdatePending, taskId: response.data.taskId}))
          yield put(actions.completeTaskDataSucccess(response.data));
        break;
      } else if (response.data.errorResponseDTO.errorCode === 1100) {
        yield delay(5000);
        const response = yield call(
          completeTask,
          action.userId,
          action.phoneNumber,
          action.workArea,
          action.stationCode,
          action.companyCode,
          action.taskCompleteData
        );
        
        if (response.data.errorResponseDTO.errorCode === 0) {
            yield put(actions.gsrtUpdatedStatusComplete({state: response.data.gsrtUpdatePending, taskId: response.data.taskId}))
            yield put(actions.completeTaskDataSucccess(response.data));
          break;
        } else {
          yield put(
            erroractions.showErrorAction(response.data.errorResponseDTO)
          );
          yield put(actions.spinnerOnCompleteTaskError());
          break;
        }
      } else {
        yield put(erroractions.showErrorAction(response.data.errorResponseDTO));
        yield put(actions.spinnerOnCompleteTaskError());
        break;
      }
    } catch (error) {
      if (
        retryCount < 2 &&
        (JSON.stringify(error.code) === '"ECONNABORTED"' ||
          JSON.stringify(error.message) === '"Network Error"')
      ) {
        yield delay(5000);
      } else {
        commonError(error);
        yield put(actions.spinnerOnCompleteTaskError());
        break;
      }
    }
  }
}

export function* fetchTaskByTimeline(action) {
  for (let retryCount = 0; retryCount < 3; retryCount++) {
    try {
      const response = yield call(
        getTaskByTimeline,
        action.userId,
        action.employeeId,
        action.phoneNumber,
        action.stationCode,
        action.companyCode,
        action.shiftId
      );

      response.data.errorResponseDTO.errorCode === 0
        ? yield put(actions.fetchTaskByTimelineSuccess(response.data))
        : yield put(
            erroractions.showErrorAction(response.data.errorResponseDTO)
          );
      break;
    } catch (error) {
      if (
        retryCount < 2 &&
        (JSON.stringify(error.code) === '"ECONNABORTED"' ||
          JSON.stringify(error.message) === '"Network Error"')
      ) {
        yield delay(5000);
      } else {
        commonError(error);
        break;
      }
    }
  }
}

export function* broadcastAck(action) {
  for (let retryCount = 0; retryCount < 3; retryCount++) {
    try {
      const response = yield call(
        acknowledgeReceipt,
        action.userId,
        action.phoneNumber,
        action.stationCode,
        action.companyCode,
        action.taskAckList,
        action.alertAckList
      );
      
      if (response.data.errorResponseDTO.errorCode === 0) {
        if (action.messageType === "broadcastAlert") {
          yield put(actions.broadcastMessageAckSuccess(response.data));
          break;
        } else {
          yield put(actions.occurenceMessageAckSuccess(response.data));
          break;
        }
      } else {
        yield put(erroractions.showErrorAction(response.data.errorResponseDTO));
        break;
      }
    } catch (error) {
      if (
        retryCount < 2 &&
        (JSON.stringify(error.code) === '"ECONNABORTED"' ||
          JSON.stringify(error.message) === '"Network Error"')
      ) {
        yield delay(5000);
      } else {
        commonError(error);
        break;
      }
    }
  }
}

export function* adhocTaskCreatefn(action) {
  for (let retryCount = 0; retryCount < 3; retryCount++) {
    try {
      const response = yield call(
        adhocTaskCreateApi,
        action.userId,
        action.station,
        action.shiftId
      );

     if(response.data.errorResponseDTO.errorCode === 0) {
          yield all([
          yield put(actions.showAdhocResponseModal({
              errorCode: 200, 
              errorMessage: "Lav Dump task request is succesfully submitted."
          })),
          yield put(actions.createAdhocTaskSuccess(response.data))
      ])  
     } else if (response.data.errorResponseDTO.errorCode === 1501) { 
        yield all([
          yield put(erroractions.showErrorAction(response.data.errorResponseDTO)),
          yield put(actions.createAdhocTaskSuccess(response.data))
        ])
     } else {     
      yield all([
        yield put(erroractions.showErrorAction(response.data.errorResponseDTO)),
        yield put(actions.createAdhocTaskSuccess(response.data))
      ])
     }

      break;
    } catch (error) {
      if (
        retryCount < 2 &&
        (JSON.stringify(error.code) === '"ECONNABORTED"' ||
          JSON.stringify(error.message) === '"Network Error"')
      ) {
        yield delay(5000);
      } else {
        commonError(error);
        break;
      }
    }
  }
}

export function* tourModeCompleted(action) {
  for (let retryCount = 0; retryCount < 3; retryCount++) {
    try {
      const response = yield call(
        setTourMode,
        action.userId,
        action.stationCode,
        action.companyCode
      );
      
      if(response.data.errorResponseDTO.errorCode === 0) {
        yield put(actions.openTourOnLogIn(false))
      }
    }
    catch (error) {
      if (
        retryCount < 2 &&
        (JSON.stringify(error.code) === '"ECONNABORTED"' ||
          JSON.stringify(error.message) === '"Network Error"')
      ) {
        yield delay(5000);
      } else {
        commonError(error);
        break;
      }
    }
  }

}
