import {
    SHOW_ERROR_MESSAGE,
    REMOVE_ERROR_MESSAGE,
    SHOW_LOADING_INDICATOR,
    REMOVE_LOADING_INDICATOR
} from './ErrorConstant';
import { getErrorMessage } from './ErrorHandler'

const initialState = {
    showErrorMessage: false,
    apiError: null,
    loadingIndicator: false    
};

const errorReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_ERROR_MESSAGE:
            return {
                ...state,
                showErrorMessage: true,
                apiError: getErrorMessage(action.error),
                errorCode: action.error.errorCode,
                loadingIndicator: false
            }
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                showErrorMessage: false,
                apiError: null,
                loadingIndicator: false
            }
        case SHOW_LOADING_INDICATOR:
            return {
                ...state,
                showErrorMessage: false,
                apiError: null,
                loadingIndicator: true
            }
        case REMOVE_LOADING_INDICATOR:
            return {
                ...state,
                showErrorMessage: false,
                apiError: null,
                loadingIndicator: false
            }        
        default:
            return state;
    }
};

export default errorReducer;