import { GET_STATION_LIST,
     GET_STATION_LIST_SUCCESS,
     GET_STATION_GATES_SUCCESS,
     GET_STATION_GATES,
     GET_WORK_AREA,
     GET_WORK_AREA_SUCCESS,
     GET_SEARCH_RESULT,
     GET_SEARCH_RESULT_SUCCESS,
     SHOW_LOADER,
     SET_STATION_SEARCH,
     GET_SEARCH_RESULT_ERROR,
     GET_STATION_GATES_ERROR,
     GET_STATION_LIST_ERROR,
     GET_WORK_AREA_ERROR,
     GET_SEARCH_DEFAULT,
     GET_SEARCH_DEFAULT_ERROR,
     GET_SEARCH_DEFAULT_SUCCESS,
     PUT_SEARCH_DEFAULT,
     PUT_SEARCH_DEFAULT_SUCCESS,
     PUT_SEARCH_DEFAULT_ERROR,
     DELETE_SEARCH_DEFAULT,
     DELETE_SEARCH_DEFAULT_SUCCESS,
     CHANGE_DEFAULT_COMPLETED_TRUE,
     CHANGE_DEFAULT_COMPLETED_FALSE,
     LOGIN_AUTH,
     CLEAR_SEARCH_RESULTS
    } from './searchConstant';

export const getStationList = () => ({
    type: GET_STATION_LIST,
});

export const getStationListError = () => ({
    type: GET_STATION_LIST_ERROR,
});

export const getStationListSuccess = (data) => ({
    type: GET_STATION_LIST_SUCCESS,
    response: data
});

export const getStationGates = (stationCode, companyCode, department) => ({
    type: GET_STATION_GATES,
    stationCode,
    companyCode,
    department
});

export const getStationGatesSuccess = (data) => ({
    type: GET_STATION_GATES_SUCCESS,
    response: data
});

export const getWorkArea = (stationCode, companyCode, department) => {
    return {
        type: GET_WORK_AREA,
        stationCode,
        companyCode,
        department
    }
};

export const getWorkAreaSuccess = (data) => {
    return {
    type: GET_WORK_AREA_SUCCESS,
    response: data
}};

export const getSearchResult = (userId = "", flightNumber = "", stationCode = "", companyCode = "", zone = [], gateNumber= [], groupTaskByDept="", department = "", adhoc = "", breakAndLunch="") => {
    return {
        type:GET_SEARCH_RESULT,
        userId,
        flightNumber,
        stationCode,
        companyCode,
        zone,
        gateNumber,
        groupTaskByDept,
        department,
        adhoc,
        breakAndLunch
    }
};

export const getSearchResultSuccess = (data) => ({
    type: GET_SEARCH_RESULT_SUCCESS,
    response: data
});

export const showLoader = (data) => {
  return  {
    type: SHOW_LOADER,
    response: data
}};

export const selectedStation = (station) => ({
    type: SET_STATION_SEARCH,
    station
}); 

export const getSearchResultError = () =>({
    type: GET_SEARCH_RESULT_ERROR
})

export const getStationGatesError = () =>({
    type: GET_STATION_GATES_ERROR
})

export const getWorkAreaError = () =>{ 
    return {
    type: GET_WORK_AREA_ERROR
}}

export const listenLogin=()=>({
    type:LOGIN_AUTH
})

export const getSearchDefault = ({userId=null}) => {
    return {
        type: GET_SEARCH_DEFAULT,
        userId
    }
}

export const getSearchDefaultError = () => ({
    type:GET_SEARCH_DEFAULT_ERROR
})

export const getSearchDefaultSuccess = (data) => {
    return {
        type:GET_SEARCH_DEFAULT_SUCCESS,
        response: data
    }
}

export const putSearchDefault = (data) => {
    return {
        type: PUT_SEARCH_DEFAULT,
        userId: data.userId,
        stationCode: data.stationCode,
        department: data.department,
        searchCriteria: data.searchCriteria,
        searchValue: data.searchValue,
        searchValues: data.searchValues,
        searchSubValues: data.searchSubValues,
        searchSubValues2: data.searchSubValues2,
    }
}

export const putSearchDefaultSuccess= (data) => ({
    type: PUT_SEARCH_DEFAULT_SUCCESS,
    response: data
})

export const putSearchDefaultError = () => ({
    type:PUT_SEARCH_DEFAULT_ERROR
})

export const deleteSearchDefault = (data) => {
    return {
        type: DELETE_SEARCH_DEFAULT,
        userId: data.userId
    }
}

export const deleteSearchDefaultSuccess = () => {
    return {
        type: DELETE_SEARCH_DEFAULT_SUCCESS
    }
}

export const changeDefaultCompletedTrue = () => {
    return {
        type: CHANGE_DEFAULT_COMPLETED_TRUE
    }
}

export const changeDefaultCompletedFalse = () => {
    return {
        type: CHANGE_DEFAULT_COMPLETED_FALSE
    }
}

export const showDefaultErrorUi = (data) => {
    return {
        type: "SHOW_DEFAULT_ERROR_UI",
        err: {...data}
    }
}

export const clearSearchResults = () => {
    return {
        type: CLEAR_SEARCH_RESULTS
    }
}