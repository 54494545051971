import {call, put, delay } from 'redux-saga/effects';
import * as actions from '../../modules/taskHistoryModule/taskHistoryAction';
import { getTaskHistorySearchApi } from '../../utils';
import * as erroractions from '../../modules/error/ErrorAction';
import { commonError } from '../../modules/error/ErrorCommonFunction';

export function* fetchTaskHistoryData(data) {
    for(let retryCount = 0; retryCount < 3; retryCount++) {
        try {
            const response = yield call(getTaskHistorySearchApi, data.data);
            if(response.data.errorResponseDTO.errorCode === 0) {
                yield put(actions.getTaskHistorySearchSuccess(response.data.taskList))
            } else {
                yield put(erroractions.showErrorAction(response.data.errorResponseDTO))
                yield put(actions.getTaskHistorySearchError())
            }
            break;
        }
        catch(error) {
            if(retryCount < 2 && (JSON.stringify(error.code) === '"ECONNABORTED"' || JSON.stringify(error.message) === '"Network Error"')) {
                yield delay(5000);
            } else {
                yield put(actions.getTaskHistorySearchError())
                commonError(error);
                break;
            }
        }
    }
}