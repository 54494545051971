import React from 'react'
import CommentIcon from '../../assets/images/Web_Comments_Icon.svg';
import resources from  '../../resources.json';

function CommentComponent( { commentsList } ) {
    return (
        <div className="commentsection">
                <div className="commentheader">
                    <img src={CommentIcon} className="CommentIcon" alt="CommentIcon"/>  
                    {resources.CommentSection}
                </div>
                <div className="commentbody">
                    <span>{commentsList}</span>
                </div>
        </div>
    )
}

export default CommentComponent
