import { put, call, all, delay } from 'redux-saga/effects';
import { initialDataDownload, setAuthorizationToken } from '../../utils';
import * as actions from '../../modules/login/LoginAction';
import * as erroractions from '../../modules/error/ErrorAction';
import * as taskactions from '../../modules/task/taskAndShiftAction';
import * as scorecardActions from '../../modules/scorecard/scorecardAction';
import { commonError } from '../../modules/error/ErrorCommonFunction';
import  resources from '../../utils/resources/resources.json';

export function* fetchLogin(params) {
    for(let retryCount = 0; retryCount < 3; retryCount++) {
        try {
            const token = localStorage.getItem('token');
            yield call(setAuthorizationToken, token, params.userId, params.stationCode, params.companyCode, resources.AZURE_BASE_URL, resources.SCORECARD_BASE_URL);
            const response = yield call(
                initialDataDownload,
                params.userId,
                params.phoneNumber,
                params.stationCode,
                "", "",
                params.deviceType,
                "",
                params.companyCode,
                "", "",
                params.adminUserId
                )
            if (response.data.errorResponseDTO.errorCode === 0 ) {
                yield put(actions.loginAsSuccess({...response.data, ...params} ))
                yield put(taskactions.fetchTaskandShiftDataAction(params.userId, params.stationCode, "D_224097", params.companyCode))
                if (params.companyCode == 'AA') {
                    yield put(scorecardActions.getUserScore({employeeId:params.userId,stationCode:params.stationCode} ))
                }
            } else {
                yield all([
                    //yield put(actions.loginAsFailure(response.data)),
                    yield put(actions.loginAsSuccess({...response.data, ...params} )),
                    yield put(taskactions.fetchTaskandShiftDataAction(params.userId, params.stationCode, "D_224097", params.companyCode)),
                    yield put(erroractions.showErrorAction(response.data.errorResponseDTO)),
                ])
                break;
            }
        }
        catch (error) {
            if(retryCount < 2 && (JSON.stringify(error.code) === '"ECONNABORTED"' || JSON.stringify(error.message) === '"Network Error"')) {
                yield delay(5000);
            } else {
                commonError(error);
                break;
            }
        }
    }
}