export const GET_STATION_LIST = 'GET_STATION_LIST';
export const GET_STATION_LIST_SUCCESS = 'GET_STATION_LIST_SUCCESS';
export const GET_STATION_GATES = 'GET_STATION_GATES';
export const GET_STATION_GATES_SUCCESS = 'GET_STATION_GATES_SUCCESS';
export const GET_WORK_AREA = 'GET_WORK_AREA';
export const GET_WORK_AREA_SUCCESS = 'GET_WORK_AREA_SUCCESS';
export const GET_SEARCH_RESULT = 'GET_SEARCH_RESULT';
export const GET_SEARCH_RESULT_SUCCESS = 'GET_SEARCH_RESULT_SUCCESS';
export const SHOW_LOADER = 'SHOW_LOADER';
export const SET_STATION_SEARCH = 'SET_STATION_SEARCH';
export const GET_SEARCH_RESULT_ERROR = 'GET_SEARCH_RESULT_ERROR';
export const GET_STATION_GATES_ERROR = 'GET_STATION_GATES_ERROR';
export const GET_STATION_LIST_ERROR = 'GET_STATION_LIST_ERROR';
export const GET_WORK_AREA_ERROR = 'GET_WORK_AREA_ERROR';
export const GET_SEARCH_DEFAULT = 'GET_SEARCH_DEFAULT';
export const GET_SEARCH_DEFAULT_ERROR = 'GET_SEARCH_DEFAULT_ERROR';
export const GET_SEARCH_DEFAULT_SUCCESS = 'GET_SEARCH_DEFAULT_SUCCESS';
export const PUT_SEARCH_DEFAULT = 'PUT_SEARCH_DEFAULT';
export const PUT_SEARCH_DEFAULT_SUCCESS = 'PUT_SEARCH_DEFAULT_SUCCESS';
export const PUT_SEARCH_DEFAULT_ERROR = 'PUT_SEARCH_DEFAULT_ERROR';

export const RESET_SEARCH_DEFAULT = 'RESET_SEARCH_DEFAULT';
export const RESET_SEARCH_DEFAULT_SUCCESS = 'RESET_SEARCH_DEFAULT_SUCCESS';

export const DELETE_SEARCH_DEFAULT = 'DELETE_SEARCH_DEFAULT';
export const DELETE_SEARCH_DEFAULT_SUCCESS = 'DELETE_SEARCH_DEFAULT_SUCCESS';

// export const CHANGE_DEFAULT_COMPLETED = "CHANGE_DEFAULT_COMPLETED";

export const CHANGE_DEFAULT_COMPLETED_FALSE = "CHANGE_DEFAULT_COMPLETED_FALSE";
export const CHANGE_DEFAULT_COMPLETED_TRUE = "CHANGE_DEFAULT_COMPLETED_TRUE";
export const CLEAR_SEARCH_RESULTS = "CLEAR_SEARCH_RESULTS";

export const LOGIN_AUTH = 'LOGIN_AUTH';