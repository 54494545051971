import {
  RESET_DATA_ACTION,
  LOGINAS_SUCCESS,
  LOGINAS_FAILURE,
  RESET_AS_HOME
  } from "./LoginConstant";

  const initialState = {
    loginAsData: {},
    responseStatusCode: -1,
    isProgress: false,
    apiError: null,
    offline: null,
    isReturnFromUser:false
  };
  
  const loginReducer = (state = initialState, action) => {
    switch (action.type) {      
      case RESET_DATA_ACTION: {
        return initialState;
      }
      case RESET_AS_HOME:{
        return {...initialState,isReturnFromUser:true};
      }
      case LOGINAS_SUCCESS: {
        let userId = '';
        let station = '';
        let firstName = action.payload.empData? action.payload.empData.firstName : '' ;
        let lastName = action.payload.empData? action.payload.empData.lastName : '' ;
        let empData = {};
          userId = action.payload.userId;
          station = action.payload.stationCode;
          empData = {userId, station, firstName, lastName}

        return {
          ...state,
          loginAsData: {
            ...action.payload,
            userId: action.payload.userId,
            phoneNumber: action.payload.phoneNumber,
            stationCode: action.payload.stationCode,
            deviceType: action.payload.deviceType,
            companyCode: action.payload.companyCode,
            adminUserId: action.payload.adminUserId,
            empData: empData
          }
        };
      }
      case LOGINAS_FAILURE: {
        return initialState;
      }
      default: {
        return state;
      }
    }
  };
  
  export default loginReducer;