import {
    GET_TIP_DATA,
    GET_TIP_DATA_SUCCESS,
    GET_SCORECARD_REPORT_SUCCESS,
    GET_SCORECARD_REPORT,
    GET_SCORE,
    GET_SCORE_SUCCESS,
    GET_FLIGHTS_SUCCESS,
    GET_FLIGHTS_DETAILS,
    GET_FLIGHTS_ERROR
} from './scorecardConstants';

export const getTipData = (metric, station) => {
    return{
    type: GET_TIP_DATA,
    metricsType: metric,
    station: station 
    }
}

export const getTipDataSuccess = (response) => ({
    type: GET_TIP_DATA_SUCCESS,
    data: response
})

export const getScorecardReportSuccess = (response) => ({
    type: GET_SCORECARD_REPORT_SUCCESS,
    data: response
})

export const getScorecardReport = (response) => ({
    type: GET_SCORECARD_REPORT,
    data: response
})

export const getUserScore = (response) => ({
    type: GET_SCORE,
    data: response
})

export const getScoreSuccess = (response) => ({
    type: GET_SCORE_SUCCESS,
    data: response
})

export const getFlightDetails = (response) => ({
    type: GET_FLIGHTS_DETAILS,
    data: response
})

export const getFlightDetailsSuccess = (response) => ({
    type: GET_FLIGHTS_SUCCESS,
    data: response
})

export const getFlightDetailsError = (response) => ({
    type: GET_FLIGHTS_ERROR,
    data: response
})
