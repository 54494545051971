import {ErrorStrings} from './ErrorStrings';

export function getErrorMessage(error){
    switch(error.errorCode) {
        case 501: return ErrorStrings.forceStartError
        case 500: return ErrorStrings.apiError
        case 502: return ErrorStrings.dbError
        case 1: return ErrorStrings.adminActionError
        case 4: return ErrorStrings.feedBackError
        case 3: return ErrorStrings.feedbackValidation
        case 1013: return error.errorMessage
        case 1021: return error.errorMessage
        case 1022: return error.errorMessage
        case 1100: return ErrorStrings.forceStartError
        case 1020: return ErrorStrings.dbError
        case 1500: return error.errorMessage
        case 1501: return error.errorMessage
        case 401: return ErrorStrings.authError
        case 200: return error.errorMessage
        case 'cancelled': return ErrorStrings.backEndError
        case 'timeout': return ErrorStrings.timeoutError
        default: return ErrorStrings.apiError;
    }
}