import { put, call, delay } from 'redux-saga/effects';
import * as actions from './code113ModalAction';
import { setCode113Alert } from '../../../utils';
import * as erroractions from '../../../modules/error/ErrorAction';
import { commonError } from '../../../modules/error/ErrorCommonFunction';


export function* submitCode113Alert(action) {
    for(let retryCount = 0; retryCount < 3; retryCount++) {
        try {
            const response = yield call(setCode113Alert,
                action.employeeId,
                action.firstName,
                action.lastName,
                action.deviceId,
                action.deviceType,
                action.stationCode,
                action.appCode,
                action.phoneNumber,
                action.gateLocation,
                action.alertMessage,
                action.shiftId,
                action.PositionName
            );
            if(response.data.response.status === 'SUCCESS') {
                yield put(actions.submitCode113Success(true));
                localStorage.setItem("showNotificationBadge", JSON.stringify(true));    
            }
            else {
                yield put(erroractions.showErrorAction({errorCode: '404', errorMessage: response.data.response.detailedMessage}));
            }
            break;
        }
        catch (error) {
            if(retryCount < 2 && (JSON.stringify(error.code) === '"ECONNABORTED"' || JSON.stringify(error.message) === '"Network Error"')) {
                yield delay(5000);
            } else {
                commonError(error);
                break;
            }
        }
    }
}
