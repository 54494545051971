export const TourSteps = {
    0: {
        step: 1,
        modal: true,
        handAction: false,
        previousStep: null,
        state: null
    },
    1: {
        step: 2,
        modal: true,
        handAction: false,
        previousStep: 0,
        state: null
    },
    2:{
        step: 3,
        modal: true,
        handAction: false,
        previousStep: 1,
        state: 'ASSIGNED'
    },
    3: {
        step: null,
        modal: false, 
        handAction: true,
        previousStep: 2,
        state: 'ASSIGNED'
    },
    4: {
        step: 4,
        modal: true,
        handAction: false,
        previousStep: 2,
        state: 'CONFIRMED'
    },
    5: {
        step: 5,
        modal: true,
        handAction: false,
        previousStep: 4,
        state: 'CONFIRMED'
    },
    6: {
        //tap to open crew details
        step: null,
        modal: false,
        handAction: true,
        previousStep: 5,
        state: 'CONFIRMED'
    },
    7: {
        //tap to close crew details
        step: null,
        modal: false,
        handAction: true,
        previousStep: 5,
        state: 'CONFIRMED'
    },
    8: {
        step: 6,
        modal: true,
        handAction: false,
        previousStep: 5,
        state: 'STARTED'

    },
    9: {
        step: null,
        modal: false,
        handAction: true,
        previousStep: 8,
        state: 'STARTED'
    },
    10: {
        step: 7,
        modal: true,
        handAction: false,
        previousStep: 8,
        //complete or cancel button
        state: 'COMPLETE1'
    },
    11: {
        step: null,
        modal: false,
        handAction: true,
        previousStep: 10,
        state: 'TimerOff'
    },
    12: {
        step: 8,
        modal: true,
        handAction: false,
        previousStep: 10,
        state: 'TimerOff'
    },
    13: {
        //counter going
        step: null,
        modal: false,
        handAction: false,
        previousStep: 12,
        state: 'TimerOn'
    },
    14: {
        step: 9,
        modal: true,
        handAction: false,
        previousStep: 12,
        state: 'COMPLETE2'
    },


}