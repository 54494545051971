export const Steps = {
    1 : {
        text: () => {
            return(
            <>  
                    <div >
                        The <span style={{fontWeight:'bold'}}>"My Tasks" </span>
                        tab is where you will find any task(s) that have been assigned to you.
                    </div>    
                </>)
        }
    },

    2: {
        text: () =>{
        return(
            <>  
                <div>
                    Let’s give you a Task and see how that looks!
                </div>
            </>
        )
        }
    },
    3: {
        text: () =>{
            return(
                <>  
                    <div  >
                        Great!  You got a task!
                    </div>
                    <div >
                        Now you will need to acknowledge it by clicking the thumbs up icon to see the details.
                    </div>
                </>
            )
        }
    },
    4: {
        text: () =>{
            return(
                <>  
                    <div >
                        Awesome!
                    </div>
                    <div>
                        Now you  can see by the orange bar that the task status changed to <span className="stepBody2" style={{color:"#FF711A",fontWeight:'bold'}}>CONFIRMED</span>. The action button will be disabled until task start time.
                    </div>
                </>
            )
        } 
    },
    5: {
        text: () =>{
            return(
                <>  
                    <p  >
                        You can check the Crew Details by tapping on the task.
                    </p>
                </>
            )
        }
    },
    6: {
        text: () =>{
            return(
                <>  
                    <div >
                        The task has <span className="stepBody2" style={{color:"#6be047",fontWeight:'bold'}}>STARTED</span>! 
                    </div>
                    <div >
                        The action button is now enabled, so once you finish the task, tap the action button to go to the next status: <span className="stepBody2" style={{fontWeight:'bold', color:'black'}}>FINISHED</span>.
                    </div> 
               </>
            )
        }
    },
    7: {
        text: () =>{
            return(
                <>  
                    <div  >
                        At this point, you can continue with the complete or cancel.
                    </div>
                    <div>
                        Click Complete.
                    </div>
                </>
            )
        }
    },
    8: {
        text: () =>{
            return(
                <>  
                    <div >
                        This counter will give you another chance to cancel the process by tapping on it and go back to <span className="stepBody2" style={{color:"#6be047",fontWeight:'bold'}}>STARTED</span> status.
                    </div>
                    <div>
                        When the counter is up, the task will be marked as <span className="stepBody2" style={{fontWeight:'bold', color:'black'}}>FINISHED</span> and the task will be removed from your My Task screen.
                    </div>
                </>
            )
        }
    },
    9: {
        text: () =>{
            return(
                <>  
                    <p  >
                        {'Rovr+ still has more features to discover. You can find this tour and more inside the Menu -> Help & Feedback -> Learn Rovr+.'}
                    </p>
                    <p className="stepBody2">
                        Do you want to continue now?
                    </p> 
                    
                </>
            )
        }
    }



}

