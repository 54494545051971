export const SHIFT = 'SHIFT';
export const FETCH_SHIFT_DATA = "FETCH_SHIFT_DATA";
export const FETCH_SHIFT_DATA_SUCCESS = "FETCH_SHIFT_DATA_SUCCESS";
export const FETCH_SHIFT_DATA_FAILURE = "FETCH_SHIFT_DATA_FAILURE";
export const FETCH_SUBWORKAREA_DATA = "FETCH_SUBWORKAREA_DATA";
export const FETCH_SUBWORKAREA_DATA_SUCCESS = "FETCH_SUBWORKAREA_DATA_SUCCESS";
export const FETCH_SUBWORKAREA_DATA_FAILURE = "FETCH_SUBWORKAREA_DATA_FAILURE";
export const SHOW_SHIFT_LOADER = "SHOW_SHIFT_LOADER";
export const CLEAR_SHIFT_SEARCH = "CLEAR_SHIFT_SEARCH";
export const SHIFT_XHR = "SHIFT_XHR";
export const TEAMS_XHR = "TEAMS_XHR";
export const FETCH_TEAMS_DATA = "FETCH_TEAMS_DATA";
export const FETCH_TEAMS_DATA_SUCCESS = "FETCH_TEAMS_DATA_SUCCESS";
export const FETCH_TEAMS_DATA_FAILURE = "FETCH_TEAMS_DATA_FAILURE";