import {
    FETCH_SET_CODE_113_SUCCESS,
    SET_CODE_113
} from './code113Constants';


export const submitCode113Action = (employeeId = '', firstName = '', lastName = '', deviceId = '', deviceType = '', stationCode = '', appCode='', phoneNumber='', gateLocation ='', alertMessage='', shiftId='', PositionName='') => ({
    type: SET_CODE_113,    
    employeeId,
    firstName,
    lastName,
    deviceId,
    deviceType,
    stationCode,
    appCode,
    phoneNumber,
    gateLocation,
    alertMessage,
    shiftId,
    PositionName
});

export const submitCode113Success = (response) => ({
    type: FETCH_SET_CODE_113_SUCCESS,
    data: response
})