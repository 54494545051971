import { put, call, delay } from 'redux-saga/effects';
import { submitFeedBack } from '../../utils';
import * as actions from '../../modules/feed-back/FeedBackAction';
import history from '../history';
import * as erroractions from '../../modules/error/ErrorAction';
import { commonError } from '../../modules/error/ErrorCommonFunction';


export function* submitFeedbackForm(action) {
    for(let retryCount = 0; retryCount < 3; retryCount++) {
        try {
            const response = yield call(submitFeedBack, action.userId, action.feedback, action.deviceType);
            
            if(response.data.errorResponseDTO.errorCode === 0) {
                yield put(actions.feedBackSubmitSuccess(response.data))
                yield call(forwardTo, '/feedbacksuccess')
            }
            else {
                yield put(erroractions.showErrorAction(response.data.errorResponseDTO))
            }
            break;
        }
        catch (error) {
            if(retryCount < 2 && (JSON.stringify(error.code) === '"ECONNABORTED"' || JSON.stringify(error.message) === '"Network Error"')) {
                yield delay(5000);
            } else {
                commonError(error);
                break;
            }
        }
    }
}

function forwardTo(location) {
    history.push(location);
  }
  