import * as actions from "./scorecardConstants";

const initialState = {
  scorecardSuccess: false,
  tipTitle: "",
  tipData: [],
  tipResources: {},
  scorecardEmployeeData: null,
  scorecardEnabled: false,
  stationMetricDTO: null,
  showLoader: true,
  currentMetricDate: null,
  flightCardDTOS: [],
  listOfMetricDates: [],
  flightDetailsError: false,
  userRanking: null,
  stationSize: null,
  isMetricCountMet: true,
  workArea: "BTW",
  departureMetrics: [],
};

const ScorecardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_TIP_DATA: {
      return {
        ...state,
        scorecardSuccess: false,
      };
    }
    case actions.GET_TIP_DATA_SUCCESS: {
      return {
        ...state,
        scorecardSuccess: true,
        tipTitle: action.data.tipTitle,
        tipData: action.data.tips[0],
        tipResources: action.data.tipsResources,
      };
    }
    case actions.GET_SCORECARD_REPORT_SUCCESS: {
      return {
        ...state.scorecardSuccess,
        scorecardEmployeeData: action.data,
      };
    }

    case actions.GET_SCORE: {
      return {
        ...state,
        showLoader: true,
      };
    }

    case actions.GET_SCORE_SUCCESS: {
      return {
        ...state,
        scorecardEmployeeData: action.data.employeeMetricsDTO
          ? action.data.employeeMetricsDTO
          : {},
        scorecardEnabled: action.data.scorecardEnabled,
        workArea: action.data.workArea,
        stationMetricDTO: action.data.stationMetricDTO
          ? action.data.stationMetricDTO
          : {},
        showLoader: false,
        userRanking:
          action.data.userRanking || action.data.userRanking === 0
            ? action.data.userRanking
            : null,
        stationSize:
          action.data.stationAgentCount || action.data.stationAgentCount === 0
            ? action.data.stationAgentCount
            : null,
        isMetricCountMet: action.data.metricCountMet,
        departureMetrics: action.data.departureMetrics,
        userControllableRanking: action.data.userControllableRanking,
      };
    }

    case actions.GET_FLIGHTS_SUCCESS: {
      return {
        ...state,
        currentMetricDate: action.data.currentMetricDate,
        flightCardDTOS: action.data.flightCardDTOS,
        listOfMetricDates: action.data.listOfMetricDates
          ? action.data.listOfMetricDates
          : [],
        flightDetailsError: false,
      };
    }

    case actions.GET_FLIGHTS_ERROR: {
      return {
        ...state,
        flightDetailsError: true,
      };
    }

    default:
      return state;
  }
};

export default ScorecardReducer;
