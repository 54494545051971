export const ErrorStrings = {
    forceStartError: 'Unable to complete your request. Please contact your allocator',
    backEndError: 'Could not connect to network',
    dbError: 'Unexpected data error occurred. Please contact your allocator',
    adminActionError: 'Sorry, you are not authorized to perform this action',
    feedBackError: 'Unable to submit feedback. Please try again later',
    feedbackValidation: 'Please enter Feedback to submit',
    apiError: 'Unexpected server error occurred. Please contact your allocator',
    authError: 'Session expired…. Signing out…..',
    timeoutError: 'Unexpected server error occurred. Please refresh the page or retry after sometime',
    err: "Please do some valid changes to request search default update."
}