import colorsVariable from '../../../assets/sass/_color.scss';
import fontVariable from '../../../assets/sass/_typography.scss';

export const crewStyles = {
    table: {
      style: {
        //border: '1px solid' + colorsVariable.verticalLine,
      }
    },
    headCells: {
      style: {
        color: colorsVariable.tableHeaderBg,
        fontFamily: fontVariable.americanSansBold,
        fontSize:  '1rem'       
      },
    },
    cells: {
      style: {
        color: colorsVariable.navbarTextColor,
        fontFamily: fontVariable.americanSansRegular,
        fontSize:  '1rem',
        paddingLeft: '25px'
      },
    }
};

export const crewBlankStyles = {
  table: {
    style: {
      //border: '1px solid' + colorsVariable.verticalLine,
    }
  },
  rows: {
    style: {
      paddingLeft: '15px',
      fontFamily: fontVariable.americanSansRegular,
    }
  },
  headCells: {
    style: {
      color: colorsVariable.tableHeaderBg,
      fontFamily: fontVariable.americanSansBold,
      fontSize:  '16px',
      paddingLeft: '15px'
    },
  },
  cells: {
    style: {
      color: colorsVariable.navbarTextColor,
      fontFamily: fontVariable.americanSansRegular,
      fontSize:  '16px'
    
    },
  },
};

export const scorecardTableStyle = {
  responsiveWrapper: {
    fixedHeader: true,
    
  },
  table: {
    style: {
      border: '1px solid #D8D8D8',
    }
  },
  head: {
    style: {
      fontFamily: fontVariable.americanSansBold,
    }
  },
  
  headCells: {
    style: {
      color: colorsVariable.tableHeaderBg,
      fontSize:  '1rem',
      fontFamily: 'AmericanSans-Regular',
      '&:not(:first-of-type)': {
        justifyContent: 'center',
        
      }
    },
  },
  cells: {
    style: {
      color: colorsVariable.navbarTextColor,
      fontFamily: 'AmericanSans-Regular',
      fontSize:  '1rem',
      '&:not(:first-of-type)': {
        justifyContent: 'center',
      }
    },
  },
  rows: {
    style:{
      alignSelf: 'center',
      marginTop: '15px',
      justifyContent: 'center',
      
    }
  }
};